import _ from "lodash";
import React from "react";
import {
  getMediaIds,
} from "../../qalbox/media/helpers/prodSyncHelper";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { useFetcMatchedModule } from "./hooks/useFetchMatchModule";
import { getModulesSyncColumns } from "./helpers/modulesSyncColumns";
import { useFetcDataToSync } from "./hooks/useFetchDataToSync";
import { useSyncToProdModule } from "./hooks/useSyncToProdModule";

export default function ModuleProdSyncModal({ module, onProdSyncCompleted }) {
  const [matchedModuleResponse, isLoading, error] = useFetcMatchedModule(module);
  const [dataToSyncResponse] = useFetcDataToSync(module);
  const [syncProdModuleHook, isSyncing, errorSyncing] = useSyncToProdModule();

  const handleProdSync = (selectedFlatRows, resetSelection) => {
    if (!matchedModuleResponse && !dataToSyncResponse) {
      return;
    }

    syncProdModuleHook({module: {...module}, ...dataToSyncResponse}, onProdSyncCompleted)
  };

  return (
    <LoadingOverlay active={isSyncing} spinner={<ScaleLoader />} text="Loading....">
      <QalboxTable
        table={{
          data: [{...module, ...matchedModuleResponse}],
          columns: getModulesSyncColumns(),
          handleProdSync: handleProdSync,
          isLoading: isLoading,
          tableError: error,
          isGlobalSearchOn: false,
        }}
      />
    </LoadingOverlay>
  );
}
