import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import QalboxMultiSelectDropdown from "../../qalbox/media/helperComponents/qalboxMultiSelect";
import QalboxSelectDropdown from "../../qalbox/media/helperComponents/qalboxSelect";
import { useInsertRevision } from "./hooks/useInsertRevision";
import { QalboxInputWrapper } from "../../qalbox/helperComponents/qalboxInputWrapperComponent";
import { useFetchMediaAccess } from "../revision-access/hooks/useFetchMediaAccess";
import { useFetchRevision } from "./hooks/useFetchRevision";
import { useFetchCalendlyEvents } from "./hooks/useFetchCalendlyEvents";
import { QalboxTextInput } from "../../qalbox/helperComponents/qalboxTextInput";
import { convertAccessArrayToSelectList, convertAccessArrayToSelectObject } from "../helpers/accessDropdownHelper";
import QalboxImageUploader from "../../qalbox/media/helperComponents/qalboxImageUploader";
import { convertCalendlyArrayToSelectList, convertCalendlyArrayToSelectObject } from "../helpers/calendlyDropdownHelper";
import { useUpdateRevision } from "./hooks/useUpdateRevision";

const initialData = {
    module_id: "",
    title: "",
    access_id: "",
    seat_limit: "",
    image: "",
    description: "",
    event_type_uris: [],
}

export const RevisionModal = ({ data, handleUpdates }) => {
  const [revision, setRevision] = useState(initialData);
  const [mediaAccesssResponse, isAccessLoading] = useFetchMediaAccess(true);
  const [revisionResponse, isRevisionLoading] = useFetchRevision(data?.uuid);
  const [calendlyEvents] = useFetchCalendlyEvents();
  const [insertRevisionHook] = useInsertRevision();
  const [updateRevisionHook] = useUpdateRevision();

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;
    setRevision((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelect = (name, option) => {
    setRevision((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const handleMultiSelect = (name, option) => {
    const value = option.map(item => item.value);

    setRevision((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if(revisionResponse?.id){
      updateRevisionHook(data?.uuid, revision, handleUpdates)
    }else{
      insertRevisionHook(data?.uuid, revision, handleUpdates)
    }
  }

  useEffect(() => {
    if(revisionResponse){
      setRevision(revisionResponse)
    }
  }, [revisionResponse])

  return (
    <div>
      <Row>
        <Col md={12}>
          <QalboxTextInput
            label="Title"
            name="title"
            value={revision.title}
            onChange={handleInputTextChange}
          />

          <QalboxTextInput
            label="Seat Limit"
            name="seat_limit"
            value={revision.seat_limit}
            onChange={handleInputTextChange}
          />

          <label>Revision Cover Image</label>
          <QalboxImageUploader
            name={"image"}
            value={revision.image}
            handleInputTextChange={handleInputTextChange}
          />

          <label style={{marginTop:'15px'}}>Select Revision Access Id</label>
          <QalboxSelectDropdown
              name={"access_id"}
              selectedOption={convertAccessArrayToSelectObject(mediaAccesssResponse ?? [], revision.access_id)}
              options={convertAccessArrayToSelectList(mediaAccesssResponse)}
              onChange={handleSelect}
              disabled={isAccessLoading && isRevisionLoading}
          />

          <label style={{marginTop:'15px'}}>Select Calendly Events</label>
          <QalboxMultiSelectDropdown
              name={"event_type_uris"}
              selectedOptions={convertCalendlyArrayToSelectObject(calendlyEvents ?? [], revision.event_type_uris)}
              options={convertCalendlyArrayToSelectList(calendlyEvents)}
              onChange={handleMultiSelect}
          />

          <label style={{ marginTop: "15px" }}>Revision Outline</label>
          <textarea
            name="description"
            className="form-control"
            value={revision.description}
            onChange={handleInputTextChange}
            rows={5}
          />
          
        </Col>

      </Row>

      <hr/>

      <br></br>

      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          <button className="pagination-button" onClick={handleSave}>
              Save
          </button>
        }
      />
    </div>
  );
};