import React, { useEffect, useState } from "react";

import { useFetchLessons } from "./hooks/useFetchLessons";
import { Col, Row } from "react-bootstrap";
import { qalBoxSearchMediaV2Hook } from "../../../hooks";
import { convertMediaArrayToSelectList, convertMediaArrayToSelectObject } from "../helpers/mediaDropdownHelper";
import QalboxMultiSelectDropdown from "../../qalbox/media/helperComponents/qalboxMultiSelect";
import { useUpsertLessons } from "./hooks/useUpsertLessons";
import { useDeleteLesson } from "./hooks/useDeleteLesson";
import { QalboxInputWrapper } from "../../qalbox/helperComponents/qalboxInputWrapperComponent";


const search_config = {
  language_code: "en",
  filterMediaType: "lesson",
  paginate: {
    page: 1,
    size: 200,
  },
};

export const LessonModal = ({ data, handleUpdates }) => {
  const [refresh, setRefresh] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [lessonsResponse, isLoadingLessons] = useFetchLessons(data?.uuid, refresh);
  const [courseData, setOperationData] = qalBoxSearchMediaV2Hook();
  const [upsertLessonsHook, upsertLoading] = useUpsertLessons();
  const [deleteLesson] = useDeleteLesson()

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const requestCourseData = () => {
    setOperationData({
      searchConfig: {
        ...search_config,
      },
    });
  };

  useEffect(() => {
    requestCourseData()
  }, [])

  const handleInputTextChange = (e, lessonId) => {
    const index = lessons.findIndex(lesson => lesson.lesson_id === lessonId);
    const updatedLessons = [...lessons];
    updatedLessons[index] = {...updatedLessons[index], order: e.target.value};
    setLessons(updatedLessons)
  };

  const handleSelect = (name, option) => {
    setSelectedLessons(option);
  };

  const handleOnSave = async() => {
    upsertLessonsHook(data?.uuid, lessons, handleUpdates);
  };

  const onDeleteLesson = (lessonId) => {
    deleteLesson(data?.uuid, lessonId, handleRefresh);
  }

  const onRemoveLesson = (lessonId) => {
    const filteredLessons = lessons.filter(lesson => lesson.lesson_id !== lessonId);
    setLessons(filteredLessons);
  }

  const handleAddToList = () => {
    setLessons([...lessons, ...selectedLessons.map(lesson => ({lesson_id: lesson.value, title:lesson.label, order: 0, isNewItem: true}))])
  }

  useEffect(() => {
    if(lessonsResponse){
      setLessons(lessonsResponse)
    }
  }, [isLoadingLessons])


  return (
    <div>
      <Row>
        <Col md={12}>
          <label>Select Lesson</label>
          <QalboxMultiSelectDropdown
              name={"course_id"}
              selectedOption={convertMediaArrayToSelectObject(courseData?.data ?? [], selectedLessons)}
              options={convertMediaArrayToSelectList(courseData?.data)}
              onChange={handleSelect}
              disabled={courseData?.isLoading}
          />
        </Col>

        <Col md={3} style={{ marginTop: '10px'}}>
          <button className="pagination-button" onClick={handleAddToList}>
            Add to list
          </button>
        </Col>
      </Row>

      <hr/>

      <Row>
        <Col md={8}>
          <h4>Lesson List</h4>
          <hr/>
          {
            isLoadingLessons && <>Loading...</>
          }
          {
            lessons?.map((lesson, index) => {
              return <div key={index} style={{ display: "grid", gridTemplateColumns: "5fr 1fr 1fr 1fr", gap: "10px", marginTop:'10px' }}>
              <h5> {lesson.title}</h5>
              <div><input onChange={e => handleInputTextChange(e, lesson.lesson_id)} className="form-control" value={lesson.order} /></div>
              <div><button disabled={lesson?.isNewItem} className="toolbar-button delete" onClick={() => onDeleteLesson(lesson.lesson_id)}>Delete</button></div>
              {lesson?.isNewItem && <div><button style={{width: "50px", backgroundColor: "yellow", color: "black"}} className="toolbar-button" onClick={() => onRemoveLesson(lesson.lesson_id)}>X</button></div>}
            </div>
            })
          }
        </Col>
      </Row>

      <br></br>

      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          <button className="pagination-button" onClick={handleOnSave}>
              {upsertLoading ? "Saving..." : "Save"}
          </button>
        }
      />
    </div>
  );
};