import _ from "lodash";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import {
  qalBoxSearchDisplayCategoriesAdPoolHook,
  qalBoxDeleteMediaHook,
} from "../../../hooks";
import {
  getDeletableDisplayCategoriesAdPools,
  getMediaIdsToDelete,
  getDisplayCategoryAdPoolColumns,
  getDisplayCategoriesAdPoolDisplayData,
} from "../helper";
import { DEFAULT_SUPPORTED_LANGUAGE } from "../Constants";
import { confirmAlert } from "react-confirm-alert";
import AppModal from "../../../widgets/modal";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import DisplayCategoryAdPoolModal from "./displayCategoryAdPoolModal";

const DisplayCategoriesAdPool = () => {
  const [row, setRow] = useState({});
  const [filterDisplayCategoryPremium, setFilterDisplayCategoryPremium] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState({
    language: DEFAULT_SUPPORTED_LANGUAGE,
    active: "true",
    filterDisplayCategoryPremium
  });
  const [responseData, setOperationData] = qalBoxSearchDisplayCategoriesAdPoolHook();
  const [deleteResponseData, setDeleteOperationData] = qalBoxDeleteMediaHook();
  const [showDisplayCategoryAdPoolModal, setShowDisplayCategoryAdPoolModal] = useState(false);

  const onOperationComplete = (operationData) => {
    if (operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const onDeleteComplete = (operationData, resetSelectedRows) => {
    requestData(searchCriteria);
    if (operationData.isError) {
      toast.error(operationData.errorMsg, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.success(`Selected display categories items deleted successfully.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    resetSelectedRows();
  };

  const requestData = (searchCriteriaObj) => {
    setOperationData({
      searchConfig: {
        language_code: searchCriteriaObj.language,
        active_only: searchCriteriaObj.active,
        filterDisplayCategoryPremium: searchCriteriaObj.filterDisplayCategoryPremium
      },
      onOperationComplete,
    });
  };

  useEffect(() => {
    requestData(searchCriteria);
  }, [searchCriteria]);

  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} display categories items ?`}
            modalBody={getDeletableDisplayCategoriesAdPools(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              console.log(getMediaIdsToDelete(selectedFlatRows));
              setDeleteOperationData({
                deleteConfig: {
                  display_categories_ad_pool_ids: getMediaIdsToDelete(selectedFlatRows),
                  language_code: searchCriteria.language,
                  deleteType: "displayCategoryAdPool",
                },
                onOperationComplete: (response) =>
                  onDeleteComplete(response, resetSelectedRows),
              });
            }}
          />
        );
      },
    });
  };

  const onEditSelected = (selectedFlatRows, resetSelectedRows) => {
    const editableRow = _.get(selectedFlatRows[0], "values");
    console.log({editableRow})
    let data = { 
      ...editableRow,
      id: editableRow.id.props.text
    };
    setRow(data);
    setShowDisplayCategoryAdPoolModal(true);
    resetSelectedRows();
  };

  const onUpdate = (response) => {
    setShowDisplayCategoryAdPoolModal(false);
    alert(JSON.stringify(response));
    requestData(searchCriteria);
  };

  const handleCreate = () => {
    setRow({});
    setShowDisplayCategoryAdPoolModal(true);
  };

  return (
    <LoadingOverlay
      active={ _.get(deleteResponseData, "isDeleting", false)}
      spinner={<ScaleLoader />}
      text="Deleting the selected display categories items."
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Display Categories Ads Pool"}</h3>
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <button
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
              onClick={handleCreate}
            >
              Create
            </button>
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: getDisplayCategoriesAdPoolDisplayData(_.get(responseData, "data", [])),
            columns: getDisplayCategoryAdPoolColumns(),
            handleDelete: onDeleteSelected,
            handleEdit: onEditSelected,
            isLoading: _.get(responseData, "isLoading", false),
            tableError: _.get(responseData, "isError", false),
          }}
        />
      </BasicLayout>
      <AppModal
        show={showDisplayCategoryAdPoolModal}
        showBody={true}
        headingText={`Create Display Categories Ad Pool`}
        modalBody={
          <DisplayCategoryAdPoolModal
            rowData={row}
            onUpdate={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => setShowDisplayCategoryAdPoolModal(false)}
      />
    </LoadingOverlay>
  );
};

export default DisplayCategoriesAdPool;
