import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { displayCategoriesAdPoolAddOrUpdateHook } from "../../../hooks";
import { ScaleLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const intialDisplayCategoryObject = {
  id: "",
  name: "",
  android_ad_unit_id: "",
  ios_ad_unit_id: "",
  web_ad_unit_id: ""
};

export default function DisplayCategoryAdPoolModal({
  rowData = intialDisplayCategoryObject,
  onUpdate,
}) {
  const [displayCategoryObject, setDisplayCategoryObject] = useState(intialDisplayCategoryObject);
  const [isLoading, setIsLoading] = useState(false);
  const [createUpdateData, doCreateOrUpdate] = displayCategoriesAdPoolAddOrUpdateHook();

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setDisplayCategoryObject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const fileUpdateHookConfig = {};
    const items = displayCategoryObject;

    doCreateOrUpdate({
      items,
      onOperationComplete,
      ...fileUpdateHookConfig,
    });
  };

  const onOperationComplete = (response) => {
    setIsLoading(false);
    onUpdate(response);
  };

  useEffect(() => {
    console.log({rowData})
    setDisplayCategoryObject({...intialDisplayCategoryObject, ...rowData});
  }, [rowData]);

  return (
    <LoadingOverlay
      active={isLoading}
      spinner={<ScaleLoader />}
      text="Saving changes..."
    >
      <Row>
        <form onSubmit={handleFormSubmit}>
          <Col md={6}>
            <div className="form-group">
                <label>Name</label>
                <input
                  name="name"
                  value={displayCategoryObject.name}
                  onChange={handleInputTextChange}
                  type="text"
                  className="form-control"
                  placeholder="Ad Unit Name"
                />
            </div>
            <div className="form-group">
              <label>Android AD Unit ID</label>
              <input
                name="android_ad_unit_id"
                value={displayCategoryObject.android_ad_unit_id}
                onChange={handleInputTextChange}
                type="text"
                className="form-control"
                placeholder="Android AD Unit ID"
              />
            </div>
            
            <Button
              type="submit"
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
            >
              Submit
            </Button>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label>Web AD Unit ID</label>
              <input
                name="web_ad_unit_id"
                value={displayCategoryObject.web_ad_unit_id}
                onChange={handleInputTextChange}
                type="text"
                className="form-control"
                placeholder="Web AD Unit ID"
              />
            </div>
            <div className="form-group">
              <label>IOS AD Unit ID</label>
              <input
                name="ios_ad_unit_id"
                value={displayCategoryObject.ios_ad_unit_id}
                onChange={handleInputTextChange}
                type="text"
                className="form-control"
                placeholder="IOS AD Unit ID"
              />
            </div>
          </Col>
        </form>
      </Row>
    </LoadingOverlay>
  );
}
