import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import { MP_CF_QALBOX_COURSE_REVISION } from '../../../../Constants';

export const useUpdateMediaAccess = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateMediaAccessHook = async (revision_access, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.put(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${MP_CF_QALBOX_COURSE_REVISION}`,
        {revision_access}
      );

      const status = response?.status;
      if (status === 200) {
        setSuccess(true);
        handleUpdate();
      } else {
        setError("Failed to update media access");
      }
      
    } catch (error) {
      setError("An error occurred while updating the media access");
    } finally {
      setLoading(false);
    }
  };

  return [ updateMediaAccessHook, loading, error, success ];
};