import mp_cf_api from '../apis/mp_cf_api';
import { creatRequestGen2 } from "../apis/mp_cf_gen2_api";
import 'firebase/auth';
import {
  MP_CF_CONTENT_GET_METADATA_URL,
  MP_CF_CONTENT_V2_ADD,
  MP_CF_CONTENT_V2_BULK_ADD_OR_UPDATE,
  MP_CF_CONTENT_V2_BULK_DELETE,
  MP_CF_CONTENT_V2_DELETE,
  MP_CF_CONTENT_V2_GET_FOR_CONSOLE,
  MP_CF_CONTENT_V2_LIST_FOR_CONSOLE,
  MP_CF_CONTENT_V2_REMOVE_PROPERTIES,
  MP_CF_CONTENT_V2_SUGGEST_HASHTAGS_FOR_CONSOLE,
  MP_CF_CONTENT_V2_UPDATE,
  MP_CF_CONTENT_V2_UPDATE_HASHTAGS,
  MP_CF_QURAN_PLAYTLISTS_LIST_FEATURED_FOR_AC_URL,
  MP_CF_QURAN_PLAYTLISTS_BULK_UPLOAD_FOR_AC_URL,
  MP_CF_QURAN_PLAYTLISTS_EDIT_FOR_AC_URL,
  MP_CF_GET_HIJRI_JSON_URL,
  MP_CF_REDIS_FLUSH_CACHE_URL,
  MP_CF_CONTENT_V2_LIST_HASHTAGS_FOR_AC,
  MP_CF_CONTENT_V2_GET_FAVORITES,
  MP_CF_AC_ED_GET_TEMPLATES_URL,
  MP_CF_AC_ED_GET_MAILING_LISTS_URL,
  MP_CF_AC_ED_SEND_BULK_MAIL_URL,
  MP_CF_VIRTUAL_TOURS_BULK_ADD_UPDATE,
  MP_CF_CONTENT_V2_TRENDING_HASHTAGS,
  MP_CF_MG_ADD_EMAIL_TAG,
  MP_CF_MG_DELETE_EMAIL_TAG,
  MP_CF_MG_LIST_EMAIL_TAGS,
  MP_CF_AC_TRACK_USER,
  MP_CF_GET_USER,
  MP_CF_GET_SECRET_KEY,
  MP_CF_QALBOX_MEDIA_ADD_OR_UPDATE,
  MP_CF_QALBOX_DISPLAY_CATEGORIES_ADD_OR_UPDATE,
  MP_CF_QALBOX_DISPLAY_CATEGORIES_DELETE,
  MP_CF_QALBOX_SEARCH_MEDIA,
  MP_CF_QALBOX_DELETE_MEDIA,
  MP_CF_QALBOX_SEARCH_MEDIA_TAG,
  MP_CF_QALBOX_CREATE_MEDIA_TAG,
  MP_CF_QALBOX_UPDATE_MEDIA_TAG,
  MP_CF_QALBOX_DELETE_MEDIA_TAG,
  MP_CF_QALBOX_SEARCH_GENRE,
  MP_CF_QALBOX_CREATE_GENRE,
  MP_CF_QALBOX_UPDATE_GENRE,
  MP_CF_QALBOX_DELETE_GENRE,
  MP_CF_QALBOX_SEARCH_DISPLAY_CATEGORIES,
  MP_CF_AC_SEND_VERIFICATION_EMAIL,
  MP_CF_AC_DELETE_USER,
  MP_CF_AC_REVALIDATE_PURCHASE,
  MP_CF_REDIS_GET_INFO_URL,
  MP_CF_AC_UPLOAD_IMAGE,
  MP_CF_CREATE_ADMIN_LOGS,
  MP_CF_QALBOX_MEDIA_GET,
  MP_CF_QALBOX_MEDIA_SYNC_ELASTIC,
  MP_CF_QALBOX_MEDIA_ENCODE,
  MP_CF_QALBOX_ENCODE_MEDIA_GET,
  MP_CF_QALBOX_MEDIA_ENCODE_UPDATE_MEDIA_ID,
  MP_CF_QALBOX_MEDIA_PURGE_CDN_CACHE,
  MP_CF_QALBOX_MEDIA_SERIES_GET,
  MP_CF_QALBOX_MEDIA_GET_SERIES_DETAILS,
  MP_CF_QALBOX_MEDIA_SERIES_EPISODE_GET,
  MP_CF_QALBOX_SHORTS_GET,
  MP_CF_QALBOX_MEDIA_DUPLICATE_MANIFEST,
  MP_CF_QALBOX_MEDIA_ADD_OR_UPDATE_G2,
  MP_CF_QALBOX_MEDIA_GET_G2,
  MP_CF_QALBOX_SEARCH_DISPLAY_CATEGORIES_AD_POOL,
  MP_CF_QALBOX_CREATE_DISPLAY_CATEGORIES_AD_POOL,
  MP_CF_QALBOX_UPDATE_DISPLAY_CATEGORIES_AD_POOL,
  MP_CF_QALBOX_DELETE_DISPLAY_CATEGORIES_AD_POOL,
  MP_CF_QALBOX_GET_UNUTILIZED_DISPLAY_CATEGORIES_AD_POOL
} from '../Constants';

const callMuslimProCFAPI = async ({
                                    data,
                                    url,
                                    method = 'POST',
                                  }) => {
  return mp_cf_api(localStorage.getItem('idToken'), {
    method,
    url,
    data,
  });
};

const callMuslimProCFGen2API = async ({ data, url, method = "POST" }) => {
  return  creatRequestGen2(localStorage.getItem("idToken"), {
    method,
    url,
    data,
  });
};

const getV2ContentData = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_GET_FOR_CONSOLE,
    data,
  });
};

const createV2Content = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_ADD,
    data,
  });
};

const updateV2Content = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_UPDATE,
    data,
  });
};

const deleteV2Content = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_DELETE,
    data,
  });
};

const deleteV2ContentProperties = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_REMOVE_PROPERTIES,
    data,
  });
};

const listV2Contents = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_LIST_FOR_CONSOLE,
    data,
  });
};

const createOrUpdateV2BulkContents = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_BULK_ADD_OR_UPDATE,
    data,
  });
};

const deleteV2BulkContents = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_BULK_DELETE,
    data,
  });
};

const suggestV2HashTags = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_SUGGEST_HASHTAGS_FOR_CONSOLE,
    data,
  });
};

const updateV2HashTags = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_UPDATE_HASHTAGS,
    data,
  });
};

const listV2HashTags = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_LIST_HASHTAGS_FOR_AC,
    data,
  });
};

const getV2Contents = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_GET_FAVORITES,
    data,
  });
};

const getContentMetaData = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_GET_METADATA_URL,
    data,
  });
};

const getQuranPlaylists = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QURAN_PLAYTLISTS_LIST_FEATURED_FOR_AC_URL,
    data,
  });
};

const editQuranPlaylists = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QURAN_PLAYTLISTS_EDIT_FOR_AC_URL,
    data,
  });
};

const bulkUploadQuranPlaylists = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QURAN_PLAYTLISTS_BULK_UPLOAD_FOR_AC_URL,
    data,
  });
};

const getHijriJSON = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_GET_HIJRI_JSON_URL,
    data,
  });
};

const redisFlushCacheForCF = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_REDIS_FLUSH_CACHE_URL,
    data,
  });
};

const redisGetInfoForCF = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_REDIS_GET_INFO_URL,
    data,
  });
};

const getEmailTemplates = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_AC_ED_GET_TEMPLATES_URL,
    data
  });
};

const getMailingLists = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_AC_ED_GET_MAILING_LISTS_URL,
    data
  });
};

const sendBulkMail = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_AC_ED_SEND_BULK_MAIL_URL,
    data
  });
};

const bulkAddUpdateVirtualTours = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_VIRTUAL_TOURS_BULK_ADD_UPDATE,
    data
  });
};

const fetchContentV2TrendingHashtags = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_CONTENT_V2_TRENDING_HASHTAGS,
    data
  });
};

const addMailGunEmailTag = async (data) => {
  return callMuslimProCFAPI({
    method: 'PUT',
    url: MP_CF_MG_ADD_EMAIL_TAG,
    data,
  });
};

const deleteMailGunEmailTag = async (data) => {
  return callMuslimProCFAPI({
    method: 'DELETE',
    url: MP_CF_MG_DELETE_EMAIL_TAG,
    data,
  });
};

const listMailGunEmailTags = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_MG_LIST_EMAIL_TAGS,
    data,
  });
};

const trackUser = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_AC_TRACK_USER,
    data,
  });
};

const getUser = async (identifier) => {
  return callMuslimProCFAPI({
    method: 'GET',
    url: `${MP_CF_GET_USER}/${identifier}`,
  });
};

const adminConsoleDeleteUser = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_AC_DELETE_USER,
    data,
  });
};

const getSecretKey = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_GET_SECRET_KEY,
    data,
  });
};

const qalBoxAddOrUpdateMediaItems = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_MEDIA_ADD_OR_UPDATE,
    data,
  });
};

const qalBoxAddOrUpdateMediaItemsG2 = async (data) => {
  return callMuslimProCFGen2API({
    url: MP_CF_QALBOX_MEDIA_ADD_OR_UPDATE_G2,
    data
  });
};

const qalBoxAddOrUpdateDisplayCategories = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_DISPLAY_CATEGORIES_ADD_OR_UPDATE,
    data
  });
};

const qalBoxSyncElastic = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_MEDIA_SYNC_ELASTIC,
    data
  });
};

const qalBoxEncodeMedia = async (data) => {
  return callMuslimProCFGen2API({
    url: 'qalbox-admin-media-service/v1/create/encode/',
    data
  });
};

const qalBoxPurgeCdnCache = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_MEDIA_PURGE_CDN_CACHE,
    data
  });
};

const qalBoxDuplicateManifest = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_MEDIA_DUPLICATE_MANIFEST,
    data
  });
};

const qalBoxUpdateEncodeMediaId = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_MEDIA_ENCODE_UPDATE_MEDIA_ID,
    data
  });
};

const qalBoxSearchMedia = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_SEARCH_MEDIA,
    data
  });
};

const qalBoxSearchMediaV2 = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_MEDIA_GET,
    ...data
  });
};

const qalBoxSearchMediaG2 = async (data) => {
  return callMuslimProCFGen2API({
    url: MP_CF_QALBOX_MEDIA_GET_G2,
    ...data
  });
};

const qalBoxgetShorts = async (data) => {
  return callMuslimProCFGen2API({
    url: `${MP_CF_QALBOX_SHORTS_GET}`,
    method: "GET"
  });
};

const qalBoxGetMediaSeriesDetails = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_MEDIA_GET_SERIES_DETAILS,
    ...data
  });
};

const qalBoxGetEncodeMedia = async (data) => {
  return callMuslimProCFGen2API({
    url: 'qalbox-admin-media-service/v1/get/encode/',
    ...data
  });
};

const qalBoxGetMediaSeriesList = async () => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_MEDIA_SERIES_GET,
  });
};

const qalBoxGetMediaSeriesEpisodeList = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_MEDIA_SERIES_EPISODE_GET,
    data
  });
};

const qalBoxSearchMediaTag = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_QALBOX_SEARCH_MEDIA_TAG,
    data
  });
};

const qalBoxSaveMediaTag = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_QALBOX_CREATE_MEDIA_TAG,
    data
  });
};

const qalBoxUpdateMediaTag = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_QALBOX_UPDATE_MEDIA_TAG,
    data
  });
};

const qalBoxDeleteMediaTag = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_QALBOX_DELETE_MEDIA_TAG,
    data
  });
};

const qalBoxSearchGenres = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_QALBOX_SEARCH_GENRE,
    data
  });
};

const qalBoxSaveGenre = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_QALBOX_CREATE_GENRE,
    data
  });
};

const qalBoxUpdateGenre = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_QALBOX_UPDATE_GENRE,
    data
  });
};

const qalBoxDeleteGenre = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_QALBOX_DELETE_GENRE,
    data
  });
};

const qalBoxDeleteMedia = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_DELETE_MEDIA,
    data
  });
};

const qalBoxSearchDisplayCategories = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_SEARCH_DISPLAY_CATEGORIES,
    data
  });
};

const qalBoxDeletehDisplayCategories = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_QALBOX_DISPLAY_CATEGORIES_DELETE,
    data
  });
};

const adminConsoleSendVerificationEmail = async (data) => {
  return callMuslimProCFAPI({
    url: MP_CF_AC_SEND_VERIFICATION_EMAIL,
    data
  });
};

const adminConsoleRevalidatePurchase = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_AC_REVALIDATE_PURCHASE,
    data,
  });
};

const adminConsoleUploadImage = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_AC_UPLOAD_IMAGE,
    data,
  });
};

const createAdminLogs = async (data) => {
  return callMuslimProCFAPI({
    method: 'POST',
    url: MP_CF_CREATE_ADMIN_LOGS,
    data,
  });
};

const qalBoxSearchDisplayCategoriesAdPool = async (data) => {
  return callMuslimProCFGen2API({
    method: 'GET',
    url: MP_CF_QALBOX_SEARCH_DISPLAY_CATEGORIES_AD_POOL,
    data
  });
};

const qalBoxGetUnitilizedDisplayCategoriesAdPool = async (data) => {
  return callMuslimProCFGen2API({
    method: 'GET',
    url: MP_CF_QALBOX_GET_UNUTILIZED_DISPLAY_CATEGORIES_AD_POOL,
    data
  });
};

const qalBoxAddDisplayCategoriesAdPool = async (data) => {
  return callMuslimProCFGen2API({
    url: MP_CF_QALBOX_CREATE_DISPLAY_CATEGORIES_AD_POOL,
    data
  });
};

const qalBoxUpdateDisplayCategoriesAdPool = async (data) => {
  return callMuslimProCFGen2API({
    method: 'PUT',
    url: MP_CF_QALBOX_UPDATE_DISPLAY_CATEGORIES_AD_POOL,
    data
  });
};

const qalBoxDeleteDisplayCategoriesAdPool = async (data) => {
  return callMuslimProCFGen2API({
    method: 'DELETE',
    url: MP_CF_QALBOX_DELETE_DISPLAY_CATEGORIES_AD_POOL,
    data
  });
};

export {
  getContentMetaData,
  getV2ContentData,
  createV2Content,
  updateV2Content,
  deleteV2Content,
  listV2Contents,
  getV2Contents,
  createOrUpdateV2BulkContents,
  deleteV2BulkContents,
  deleteV2ContentProperties,
  suggestV2HashTags,
  updateV2HashTags,
  listV2HashTags,
  getQuranPlaylists,
  editQuranPlaylists,
  bulkUploadQuranPlaylists,
  getHijriJSON,
  redisFlushCacheForCF,
  getEmailTemplates,
  getMailingLists,
  sendBulkMail,
  bulkAddUpdateVirtualTours,
  fetchContentV2TrendingHashtags,
  addMailGunEmailTag,
  deleteMailGunEmailTag,
  listMailGunEmailTags,
  trackUser,
  getUser,
  getSecretKey,
  qalBoxAddOrUpdateMediaItems,
  qalBoxAddOrUpdateMediaItemsG2,
  qalBoxAddOrUpdateDisplayCategories,
  qalBoxDeletehDisplayCategories,
  qalBoxSearchMedia,
  qalBoxDeleteMedia,
  qalBoxSearchMediaTag,
  qalBoxSaveMediaTag,
  qalBoxUpdateMediaTag,
  qalBoxDeleteMediaTag,
  qalBoxSearchGenres,
  qalBoxSaveGenre,
  qalBoxUpdateGenre,
  qalBoxDeleteGenre,
  qalBoxSearchDisplayCategories,
  adminConsoleSendVerificationEmail,
  adminConsoleDeleteUser,
  adminConsoleRevalidatePurchase,
  redisGetInfoForCF,
  adminConsoleUploadImage,
  createAdminLogs,
  qalBoxSyncElastic,
  qalBoxSearchMediaV2,
  qalBoxSearchMediaG2,
  qalBoxEncodeMedia,
  qalBoxGetEncodeMedia,
  qalBoxUpdateEncodeMediaId,
  qalBoxPurgeCdnCache,
  qalBoxGetMediaSeriesList,
  qalBoxGetMediaSeriesDetails,
  qalBoxGetMediaSeriesEpisodeList,
  qalBoxgetShorts,
  callMuslimProCFGen2API,
  qalBoxDuplicateManifest,
  qalBoxSearchDisplayCategoriesAdPool,
  qalBoxAddDisplayCategoriesAdPool,
  qalBoxUpdateDisplayCategoriesAdPool,
  qalBoxDeleteDisplayCategoriesAdPool,
  qalBoxGetUnitilizedDisplayCategoriesAdPool
}
