import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { useInsertQuiz, useInsertRevision } from "./hooks/useInsertQuiz";
import { QalboxInputWrapper } from "../../qalbox/helperComponents/qalboxInputWrapperComponent";
import { useFetchQuiz } from "./hooks/useFetchQuiz";
import { QalboxTextInput } from "../../qalbox/helperComponents/qalboxTextInput";
import QalboxImageUploader from "../../qalbox/media/helperComponents/qalboxImageUploader";
import { useUpdateQuiz, useUpdateRevision } from "./hooks/useUpdateQuiz";
import QalboxSelectDropdown from "../../qalbox/media/helperComponents/qalboxSelect";
import { quizType } from "../constant";
import { convertQuizTypeToSelectObject } from "../helpers/quizTypeDropdownHelper";

const initialData = {
  title: "",
  caption: "",
  background: "",
  thumbnail: "",
  steps: [],
};

export const QuizModal = ({ data, handleUpdates }) => {
  const [quiz, setQuiz] = useState(initialData);
  const [quizResponse, isQuizLoading] = useFetchQuiz(data?.uuid);
  const [insertQuizHook] = useInsertQuiz();
  const [updateQuizHook] = useUpdateQuiz();

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;
    setQuiz((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStepsOnChange = (e, idx) => {
    let { name, value } = e.target;
    const quizData = quiz;
    quizData.steps[idx][name] = value;
    setQuiz({ ...quizData });
  };

  const handleSelect = (name, option, idx) => {
    const quizData = quiz;
    quizData.steps[idx][name] = option.value;
    if(option.value !== 'mcq'){
      delete quizData.steps[idx].data; 
    }
    setQuiz({ ...quizData });
  };

  const handleCheck = (idx, ansIdx) => {
    const quizData = quiz;
    quizData.steps[idx].data.answers = quizData.steps[idx].data.answers.map((ans) => ({ ...ans, correct: false }));
    quizData.steps[idx].data.answers[ansIdx] = {
      ...quizData.steps[idx]?.data?.answers[ansIdx],
      correct: !quizData.steps[idx]?.data?.answers[ansIdx].correct,
    };
    setQuiz({ ...quizData });
  };

  const handleOptions = (e, idx, ansIdx) => {
    const { value } = e.target;
    const quizData = quiz;
    quizData.steps[idx].data.answers[ansIdx] = { ...quizData.steps[idx]?.data?.answers[ansIdx], answer_text: value };
    setQuiz({ ...quizData });
  };

  const handleAddNewStep = () => {
    const quizData = quiz;
    quizData.steps.push({index: quizData.steps.length, type: "", description: ""})
    setQuiz({ ...quizData });
  };

  const addOption = (idx) => {
    const quizData = quiz;
    const index = quizData.steps[idx]?.data?.answers?.length;

    if (index) {
      quizData.steps[idx]?.data?.answers.push({ index: index + 1, answer_text: "", correct: false });
    } else {
      quizData.steps[idx] = {
        ...quizData.steps[idx],
        data: { answers: [{ index: 0, answer_text: "", correct: false }] },
      };
    }

    setQuiz({ ...quizData });
  };

  const handleSave = () => {
    if(quiz.id){
      updateQuizHook(data?.uuid, quiz, handleUpdates)
    }else{
      insertQuizHook(data?.uuid, quiz, handleUpdates)
    }
  };

  useEffect(() => {
    if (quizResponse) {
      setQuiz(quizResponse);
    }
  }, [quizResponse]);

  if(isQuizLoading){
    return <>Loading...</>
  }

  return (
    <div>
      <Row>
        <Col md={12}>
          <QalboxTextInput label="Title" name="title" value={quiz.title} onChange={handleInputTextChange} />

          <QalboxTextInput label="Caption" name="caption" value={quiz.caption} onChange={handleInputTextChange} />

          <label>Background Image</label>
          <QalboxImageUploader
            name={"background"}
            value={quiz.background}
            handleInputTextChange={handleInputTextChange}
          />

          <label>Thumbnail Image</label>
          <QalboxImageUploader
            name={"thumbnail"}
            value={quiz.thumbnail}
            handleInputTextChange={handleInputTextChange}
          />

          <label style={{ marginTop: "20px" }}>Steps</label>
          {quiz.steps?.map((item, idx) => {
            return (
              <div style={{ padding: "20px", border: "1px solid #ccc", marginBottom: "20px", borderRadius: "10px" }}>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "20px" }}>
                  <QalboxTextInput
                    label="Index"
                    name="index"
                    value={item.index}
                    onChange={(e) => handleStepsOnChange(e, idx)}
                  />

                  <div>
                    <label>Type</label>
                    <QalboxSelectDropdown
                      name={"type"}
                      selectedOption={convertQuizTypeToSelectObject(quizType, item.type)}
                      options={quizType}
                      onChange={(name, option) => handleSelect(name, option, idx)}
                    />
                  </div>
                </div>

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "20px" }}>
                  <div>
                    <label>Question / Description</label>
                    <textarea
                      name="description"
                      className="form-control"
                      value={item.description}
                      onChange={(e) => handleStepsOnChange(e, idx)}
                    />
                  </div>

                  <div>
                    {item.type === "mcq" && <label style={{ marginTop: "10px" }}>Options</label>}
                    {item.data?.answers?.map((ans, ansIdx) => {
                      return (
                        <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "5px" }}>
                          <input
                            name={`correct-${idx}`}
                            type="radio"
                            checked={ans.correct}
                            onChange={() => handleCheck(idx, ansIdx)}
                          />
                          <input
                            name="answer_text"
                            type="text"
                            value={ans.answer_text}
                            onChange={(e) => handleOptions(e, idx, ansIdx)}
                            placeholder="Enter text"
                            style={{ padding: "5px", border: "1px solid #ccc", borderRadius: "4px", width: "300px" }}
                          />
                        </div>
                      );
                    })}

                    {item.type === "mcq" && (
                      <button
                        className="pagination-button"
                        style={{ marginTop: "10px" }}
                        onClick={() => addOption(idx)}
                      >
                        Add Option
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          <button className="pagination-button" style={{ marginTop: "10px" }} onClick={handleAddNewStep}>
            Add New Step
          </button>
        </Col>
      </Row>

      <hr />

      <br></br>

      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          <button className="pagination-button" onClick={handleSave}>
            Save
          </button>
        }
      />
    </div>
  );
};
