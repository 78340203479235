import { useState } from 'react';
import { MP_CF_QALBOX_COURSE_MODULE } from '../../../../Constants';

export const useSyncToProdModule = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const syncProdModuleHook = async (data, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_PROD_SYNC_URL}${MP_CF_QALBOX_COURSE_MODULE}/sync/prod`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json"
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to sync to prod modules (status ${response.status})`);
      }

      setSuccess(true);
      handleUpdate();
    } catch (err) {
      console.error(err);
      setError(err.message || 'An error occurred while syncing to prod modules');
    } finally {
      setLoading(false);
    }
  };

  return [ syncProdModuleHook, loading, error, success ];
};