export const quizType = [
    {
        label: "Intro",
        value: 'intro'
    },
    {
        label: "Outro",
        value: 'outro'
    },
    {
        label: "MCQ",
        value: 'mcq'
    },
    {
        label: "Review Complete",
        value: 'review_complete'
    },
    {
        label: "Content",
        value: 'content'
    }
]