import { useState, useEffect } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import { MP_CF_QALBOX_COURSE_MODULE } from '../../../../Constants';

export const useFetchRevision = (moduleId) => {
  const [revisionResponse, setRevisionResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRevision = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axiosService.get(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${MP_CF_QALBOX_COURSE_MODULE}/${moduleId}/module-revision`
        );
        setRevisionResponse(response?.data);
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while fetching revision');
      } finally {
        setIsLoading(false);
      }
    };

    if(moduleId){
      fetchRevision();
    }
  }, [moduleId]);

  return [ revisionResponse, isLoading, error ];
};