import _ from "lodash";

export const convertMediaArrayToSelectList = (objectArray) => {
  return _.map(objectArray, (item) => {
    return {
      value: item.uuid,
      label: item.title,
    };
  });
};

export const convertMediaArrayToSelectObject = (objectArray, value) => {
    const findData = objectArray?.find(item => item.uuid === value)

    return {
      label: findData?.title,
      value: value,
    };
};