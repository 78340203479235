import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import { MP_CF_QALBOX_COURSE_MODULE } from '../../../../Constants';

export const useUpdateModule = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateModuleHook = async (module, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.put(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${MP_CF_QALBOX_COURSE_MODULE}`,
        {module: module}
      );

      const json = response?.data;
      if (json?.payload) {
        setSuccess(true);
        handleUpdate(json?.payload);
      } else {
        setError("Failed to update module");
      }
      
    } catch (error) {
      setError("An error occurred while updating the module");
    } finally {
      setLoading(false);
    }
  };

  return [ updateModuleHook, loading, error, success ];
};