import { useState, useEffect } from 'react';
import { MP_CF_QALBOX_COURSE_FAQ } from '../../../../Constants';

export const useFetcMatchedFaq = (faq, refresh) => {
  const [matchedFaqResponse, setMatchedFaqResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModuleMatchProd = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_PROD_SYNC_URL}${MP_CF_QALBOX_COURSE_FAQ}/match/prod`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json"
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({ faq: faq }),
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch matched faq (status ${response.status})`);
        }

        const matchedList = await response.json();        
        setMatchedFaqResponse(matchedList);
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while fetching matched faq');
      } finally {
        setIsLoading(false);
      }
    };

    if (module) {
      fetchModuleMatchProd();
    }

  }, [module, refresh]);

  return [ matchedFaqResponse, isLoading, error ];
};