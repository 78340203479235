import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import { MP_CF_QALBOX_COURSE_MODULE } from '../../../../Constants';
import { toast } from 'react-toastify';

export const useInsertResources = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const insertResourcesHook = async (moduleId, resources, handleUpdates) => {
    setLoading(true);
    setError(null);
    setSuccess(false);
  
    try {
      const requests = resources.map((resource) =>
        axiosService.post(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${MP_CF_QALBOX_COURSE_MODULE}/${moduleId}/resources`,
          { resource: resource }
        )
      );
  
      // Wait for all API calls to complete
      const responses = await Promise.all(requests);
  
      // Check if all requests were successful
      const allSuccessful = responses.every((res) => res.status === 200);
  
      if (allSuccessful) {
        setSuccess(true);
        toast.success(`Resources created successfully.`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        handleUpdates();
      } else {
        setError("Some resources failed to create.");
      }
    } catch (error) {
      setError("An error occurred while creating resources.");
    } finally {
      setLoading(false);
    }
  };
  

  return [ insertResourcesHook, loading, error, success ];
};