import React, { useEffect, useState } from "react";
import { QalboxInputWrapper } from "../../qalbox/helperComponents/qalboxInputWrapperComponent";
import { QalboxTextInput } from "../../qalbox/helperComponents/qalboxTextInput";
import QalboxImageUploader from "../../qalbox/media/helperComponents/qalboxImageUploader";
import { useFetchResources } from "./hooks/useFetchResources";
import { useInsertResources } from "./hooks/useInsertResources";
import { useDeleteResources } from "./hooks/useDeleteResources";
import _ from "lodash";
import { useUpdateResources } from "./hooks/useUpdateResources";

export const ResourcesModal = ({ data, handleUpdates }) => {
  const [refresh, setRefresh] = useState(false);
  const [resources, setResources] = useState([]);
  const [resourcesResponse, isLoadingResources] = useFetchResources(data?.uuid, refresh);
  const [insertResourcesHook] = useInsertResources()
  const [deleteResource] = useDeleteResources()
  const [updateResourcesHook] = useUpdateResources()

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const handleTextChange = (e, idx, idx2) => {
    const {name, value} = e.target;
    const resource = [...resources];

    if(idx2 >= 0){
      const innerResource = resource[idx].resource;
      innerResource[idx2] = {...innerResource[idx2], [name]: value};
      resource[idx] = {...resource[idx], resource: [...innerResource]}
    }else{
      resource[idx] = {...resource[idx], [name]: value};
    }

    setResources([...resource]);
  };

  const handleAddNew = (idx) => {
    const allResource = resources ?? [];
    const resource = [{title: "", caption: "", link: "", image: ""}]
    const newResouce = {title: "", caption: "", resource: resource}
    if(idx >= 0){
      allResource[idx] = {...allResource[idx], resource: [...allResource[idx].resource, resource]}
    } else {
      allResource.push(newResouce)
    }
    
    setResources([...allResource]);
  }

  const handleOnSave = () => {
    if(resourcesResponse.length !== resources.length && resourcesResponse.length === 0){
      insertResourcesHook(data?.uuid, resources, handleUpdates)
    }else {
      // Identify changed objects (those with the same `id` but modified properties)
      const changedObjects = resources.filter(changedItem => {
        const initialItem = resourcesResponse.find(item => item.id === changedItem.id);
        return initialItem && !_.isEqual(initialItem, changedItem);
      });

      // Identify new objects (those that do not have an `id`)
      const newObjects = _.differenceWith(resources, changedObjects, _.isEqual).filter(obj => !obj.hasOwnProperty("id"));

      if(newObjects.length > 0){
        insertResourcesHook(data?.uuid, newObjects, handleUpdates)
      }

      if(changedObjects.length > 0){
        updateResourcesHook(data?.uuid, changedObjects, handleUpdates)
      }
    }
  };

  const handleRemove = (idx, idx2) => {
    if(idx2 >= 0){
      const allResource = resources;
      allResource[idx] = {...allResource[idx], resource: [...allResource[idx].resource.filter((resource, inx) => inx !== idx2)]}
      setResources([...allResource])
    }else{
      setResources(resources.filter((_, index) => index !== idx));
    }
  };

  const deleteResourceItem = (resourceId) => {
    deleteResource(data?.uuid, resourceId, handleRefresh)
  }

  useEffect(() => {
    if(resourcesResponse){
      setResources([...resourcesResponse])
    }
  }, [resourcesResponse])

  if(isLoadingResources){
    return <>Loading...</>
  }

  return (
    <div>
      <div>
        {
          resources?.map((item, idx) => <div style={{border: "1px solid #aaa", padding: '10px', borderRadius: '8px', marginBottom: "20px"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <button disabled={resourcesResponse[idx]?.id ? true : false} onClick={() => handleRemove(idx)} style={{marginBottom:'5px'}} className='button'>X</button>
              <button disabled={resourcesResponse[idx] ? false : true} className="toolbar-button delete" onClick={() => deleteResourceItem(item.id)} style={{marginBottom:'5px'}}>Delete</button>
            </div>

            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px"}}>
              <QalboxTextInput
                  label={`Title`}
                  name="title"
                  value={item.title}
                  onChange={(e) => handleTextChange(e, idx)}
                />
              <QalboxTextInput
                  label={`Caption`}
                  name="caption"
                  value={item.caption}
                  onChange={(e) => handleTextChange(e, idx)}
                />
            </div>
            
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
              {
                item?.resource?.map((item, idx2) => {
                  return <div style={{border: "1px solid #ccc", padding: '10px', borderRadius: '8px', marginBottom: "10px"}}>
                    <button onClick={() => handleRemove(idx, idx2)} style={{marginBottom:'5px'}} className='button'>X</button>
                    <QalboxTextInput
                      label={`Title ${idx2+1}`}
                      name="title"
                      value={item.title}
                      onChange={(e) => handleTextChange(e, idx, idx2)}
                    />
                    <QalboxTextInput
                      label="Caption"
                      name="caption"
                      value={item.caption}
                      onChange={(e) => handleTextChange(e, idx, idx2)}
                    />
                    <QalboxTextInput
                      label="Link"
                      name="link"
                      value={item.link}
                      onChange={(e) => handleTextChange(e, idx, idx2)}
                    />
                    <label style={{marginTop:'10px'}}>Image</label>
                    <QalboxImageUploader
                      name={"image"}
                      value={item.image}
                      handleInputTextChange={(e) => handleTextChange(e, idx, idx2)}
                    />
                  </div>
                })
              }
            </div>

            <button className="pagination-button" onClick={() => handleAddNew(idx)}>
              +
            </button>
              
          </div>)
        }

        <button className="pagination-button" onClick={() => handleAddNew()}>
              +
        </button>
      </div>

      <hr/>

      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          <button className="pagination-button" onClick={handleOnSave}>
              Save
          </button>
        }
      />
    </div>
  );
};