import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { useFetcMatchedFaq } from "./hooks/useFetchMatceFaq";
import { useSyncToProdFaq } from "./hooks/useSyncToProdFaq";
import { getProdFaqColumns } from "./helpers/faqProdColumns";
import _ from "lodash";

export default function FaqProdSyncModal({ faq, onProdSyncCompleted }) {
  const [matchedFaqResponse, isLoading, error] = useFetcMatchedFaq(faq);
  // const [dataToSyncResponse] = useFetcDataToSync(faq);
  const [syncProdFaqHook, isSyncing] = useSyncToProdFaq();

  const handleProdSync = (selectedFlatRows, resetSelection) => {
    if (!matchedFaqResponse) {
      return;
    }

    syncProdFaqHook(faq, onProdSyncCompleted)
  };


  return (
    <LoadingOverlay active={isLoading || isSyncing} spinner={<ScaleLoader />} text="Loading....">
      <QalboxTable
        table={{
          data: _.unionBy(matchedFaqResponse, faq, "course_id"),
          columns: getProdFaqColumns(),
          handleProdSync: handleProdSync,
          isLoading: isLoading,
          tableError: error,
          isGlobalSearchOn: false,
        }}
      />
    </LoadingOverlay>
  );
}
