import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import { MP_CF_QALBOX_COURSE_FAQ } from '../../../../Constants';

export const useDeleteFaq = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteFaq = async (id, callback) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axiosService.delete(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${MP_CF_QALBOX_COURSE_FAQ}/${id}`
      );
      const json = response?.data;
      callback(true);
      return json.success; // Return success status from the response
    } catch (err) {
      console.error(err);
      setError(err.message || 'An error occurred while deleting the faq');
      return false; // Return false if an error occurs
    } finally {
      setIsLoading(false);
    }
  };

  return [ deleteFaq, isLoading, error ];
};