import _, { parseInt } from "lodash";
import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { convertArrayToObjMap } from "../helper";
import { MPSelect } from "../../../widgets/common";
import {
  SUPPORTED_LANGUAGES,
  PLAY_RESTRICTIONS
} from "../Constants";
import {
  convertArrayToSelectList,
  getCountryCodesAsLabelValuePair,
  convertArrayToSelectAdUnits
} from "../helper";
import { qalBoxDisplayCategoriesAddOrUpdateHook } from "../../../hooks";
import { ScaleLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import QalboxMultiSelectDropdown from "../media/helperComponents/qalboxMultiSelect";
import { QalboxTagsDropdown } from "./helperComponents/QalboxTagsDropdown";
import { QalboxGenresDropdown } from "./helperComponents/QalboxGenresDropdown";
import { QalboxDisplayCategoryTypesDropdown } from "./helperComponents/QalboxDisplayCategoryTypesDropdown";
import { QalboxDisplayCategoryBrandDetails } from "./helperComponents/QalboxDisplayCategoryBrandDetails";
import { qalBoxGetUnitilizedDisplayCategoriesAdPool } from "../../../services/mp-cf-api-service";

const intialDisplayCategoryObject = {
  name: "",
  language: "",
  type: "",
  order: "",
  active: "",
  tags: [],
  genres: [],
  countries_allowed: [],
  countries_disallowed: [],
  is_branded_partner_rail: false,
  branded_partner_uuid: null,
  rail_content_type: null,
  play_restrictions: [],
  rails_ads: null
};

export default function DisplayCategoryModal({
  rowData = intialDisplayCategoryObject,
  onUpdate,
}) {
  const [displayCategoryObject, setDisplayCategoryObject] = useState(intialDisplayCategoryObject);
  const [adunits, setAdUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ad_start, setAdStart] = useState(0);
  const [ad_frequency, setAdFrequency] = useState(0);

  const [createUpdateData, doCreateOrUpdate] =
    qalBoxDisplayCategoriesAddOrUpdateHook();

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setDisplayCategoryObject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const fileUpdateHookConfig = {};
    const items = [displayCategoryObject];

    doCreateOrUpdate({
      items,
      onOperationComplete,
      ...fileUpdateHookConfig,
    });
  };

  const onOperationComplete = (response) => {
    setIsLoading(false);
    onUpdate(response);
  };

  const handleMultiSelect = (name, array) => {
    const value = array.map((x) => x.value);

    setDisplayCategoryObject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAdUnitsChange = (e) => {
    const { name, value } = e.target;
    setDisplayCategoryObject((prevState) => ({
      ...prevState,
      rails_ads: {
        ...prevState.rails_ads,
        [name === "start" ? "start" : "frequency"]: parseInt(value),
      }
    }));
  };

  const handleAdUnitSelection = (name, array) => {
    const selectedAdUnits = adunits.filter(adUnit => 
      array.some(x => x.value === adUnit.id)
    );
    if(array.length){
      setDisplayCategoryObject((prevState) => ({
        ...prevState,
        [name]: {
          frequency: (prevState.rails_ads.frequency)? parseInt(prevState.rails_ads.frequency) : 0,
          start: (prevState.rails_ads.start)? parseInt(prevState.rails_ads.start) : 0,
          ad_units: selectedAdUnits
        },
      }));
    }else {
      setDisplayCategoryObject((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    }
    
  };

  const handleCategoryType = (categoryType, brandUuid) => {
    if (brandUuid) {
      setDisplayCategoryObject((prev) => ({
        ...prev,
        type: categoryType,
        is_branded_partner_rail: true,
        branded_partner_uuid: brandUuid,
      }));
    } else {
      setDisplayCategoryObject((prev) => ({
        ...prev,
        type: categoryType,
      }));
    }
  };

  const handleInputCheckBoxChange = (e) => {
    const { name, checked } = e.target;

    setDisplayCategoryObject((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSelect = (name, option) => {

    setDisplayCategoryObject((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  useEffect(async () => {
    const mediaItemsApiResp = await qalBoxGetUnitilizedDisplayCategoriesAdPool({
      data: {},
    });
    const responseData = _.get(mediaItemsApiResp, 'data.payload', {});
    if(intialDisplayCategoryObject.rails_ads){
      const mergedAdUnits = Array.from(
        new Map(
          [...intialDisplayCategoryObject.rails_ads.ad_units, ...responseData].map(adUnit => [adUnit.id, adUnit])
        ).values()
      );
      setAdUnits(mergedAdUnits);
    }else{
      setAdUnits(responseData);
    }
    setDisplayCategoryObject({...intialDisplayCategoryObject, ...rowData});
  }, [rowData]);

  return (
    <LoadingOverlay
      active={isLoading}
      spinner={<ScaleLoader />}
      text="Saving changes..."
    >
      <Row>
        <form onSubmit={handleFormSubmit}>
          <Col md={6}>
            <div className="form-group">
              <label>Name</label>
              <input
                name="name"
                value={displayCategoryObject.name}
                onChange={handleInputTextChange}
                type="text"
                className="form-control"
                placeholder="Name"
              />
            </div>
            <div className="form-group">
              <label>Language</label>
              <MPSelect
                items={convertArrayToObjMap(SUPPORTED_LANGUAGES)}
                selectedItem={displayCategoryObject.language}
                onChangeSelect={(e) =>
                  setDisplayCategoryObject((prev) => ({
                    ...prev,
                    language: e,
                  }))
                }
              />
            </div>
            <QalboxDisplayCategoryTypesDropdown
              type={displayCategoryObject.type}
              onChange={handleCategoryType}
            />
            <QalboxDisplayCategoryBrandDetails 
             is_branded_partner_rail={displayCategoryObject.is_branded_partner_rail}
             branded_partner_uuid={displayCategoryObject.branded_partner_uuid}
             rail_content_type={displayCategoryObject.rail_content_type}
             onChangeSelect={(option) =>
              setDisplayCategoryObject((prev) => ({
                ...prev,
                rail_content_type: option,
              }))}
              handleInputCheckBoxChange={handleInputCheckBoxChange}
              handleSelect={handleSelect}
            />
            <div className="form-group">
              <label>Order</label>
              <input
                name="order"
                value={displayCategoryObject.order}
                onChange={handleInputTextChange}
                type="text"
                className="form-control"
                placeholder="Order"
              />
            </div>
            <div className="form-group">
              <label>Active</label>
              <MPSelect
                items={convertArrayToObjMap(["true", "false"])}
                selectedItem={displayCategoryObject.active}
                onChangeSelect={(e) =>
                  setDisplayCategoryObject((prev) => ({
                    ...prev,
                    active: e,
                  }))
                }
              />
            </div>
            <Button
              type="submit"
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
            >
              Submit
            </Button>
          </Col>
          <Col md={6}>
            <QalboxTagsDropdown
              tags={displayCategoryObject.tags}
              handleMultiSelect={handleMultiSelect}
            />
            <QalboxGenresDropdown
              genres={displayCategoryObject.genres}
              handleMultiSelect={handleMultiSelect}
            />
            <div className="form-group">
              <label>Countries Allowed</label>
              <QalboxMultiSelectDropdown
                name={"countries_allowed"}
                onChange={handleMultiSelect}
                selectedOptions={convertArrayToSelectList(
                  displayCategoryObject.countries_allowed
                )}
                options={getCountryCodesAsLabelValuePair()}
              />
            </div>
            <div className="form-group">
              <label>Countries Disallowed</label>
              <QalboxMultiSelectDropdown
                name={"countries_disallowed"}
                onChange={handleMultiSelect}
                selectedOptions={convertArrayToSelectList(
                  displayCategoryObject.countries_disallowed
                )}
                options={getCountryCodesAsLabelValuePair()}
              />
            </div>
            <div className="form-group">
              <label>Play Restrictions</label>
              <QalboxMultiSelectDropdown
                name={"play_restrictions"}
                onChange={handleMultiSelect}
                selectedOptions={convertArrayToSelectList(
                  displayCategoryObject.play_restrictions
                )}
                options={convertArrayToSelectList(PLAY_RESTRICTIONS)}
              />
            </div>
            <hr/>
            <h4>Rail Ads Configurations</h4>
            <br></br>
            <div className="form-group">
              <label>Ad Start</label>
              <input
                name="start"
                value={displayCategoryObject.rails_ads?.start}
                onChange={handleAdUnitsChange}
                type="number"
                className="form-control"
                placeholder="0"
              />
            </div>
            <div className="form-group">
              <label>Ad Frequency</label>
              <input
                name="frequency"
                value={displayCategoryObject.rails_ads?.frequency}
                onChange={handleAdUnitsChange}
                type="number"
                className="form-control"
                placeholder="0"
              />
            </div>
            <div className="form-group">
              <label>Ad Units</label>
              <QalboxMultiSelectDropdown
                name={"rails_ads"}
                onChange={handleAdUnitSelection}
                selectedOptions={convertArrayToSelectAdUnits(
                  displayCategoryObject.rails_ads?.ad_units
                )}
                options={convertArrayToSelectAdUnits(adunits)}
              />
            </div>
          </Col>
        </form>
      </Row>
    </LoadingOverlay>
  );
}
