import _ from "lodash";
import { CopyItem } from "../../../qalbox/common";


export const getModulesDisplayData = (items, handleRowClickView, handleCertificationModal) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    const uuid = _.get(item, "uuid");

    return {
      id: _.get(item, "id"),
      uuid: <CopyItem text={uuid} />,
      module: _.get(item, "title"),
      course: _.get(item, "course_title"),
      moduleDetailsModal: (
        <div>
          <button
            onClick={() => handleRowClickView(item, 1)}
            className="pagination-button"
          >
            &nbsp;
            <span
              className="glyphicon glyphicon-folder-open"
              aria-hidden="true"
            />
          </button>

        </div>
      ),
      moduleLessonsModal: (
        <div>
          <button
            onClick={() => handleRowClickView(item, 2)}
            className="pagination-button"
          >
            &nbsp;
            <span
              className="glyphicon glyphicon-folder-open"
              aria-hidden="true"
            />
          </button>

        </div>
      ),
      moduleResourceModal: (
        <div>
          <button
            onClick={() => handleRowClickView(item, 3)}
            className="pagination-button"
          >
            &nbsp;
            <span
              className="glyphicon glyphicon-folder-open"
              aria-hidden="true"
            />
          </button>

        </div>
      ),
      moduleRevisionModal: (
        <div>
          <button
            onClick={() => handleRowClickView(item, 4)}
            className="pagination-button"
          >
            &nbsp;
            <span
              className="glyphicon glyphicon-folder-open"
              aria-hidden="true"
            />
          </button>

        </div>
      ),
      moduleQuizModal: (
        <div>
          <button
            onClick={() => handleRowClickView(item, 5)}
            className="pagination-button"
          >
            &nbsp;
            <span
              className="glyphicon glyphicon-folder-open"
              aria-hidden="true"
            />
          </button>

        </div>
      ),
      moduleCertificationModal: (
        <div>
          <button
            onClick={() => handleCertificationModal(item)}
            className="pagination-button"
          >
            &nbsp;
            <span
              className="glyphicon glyphicon-folder-open"
              aria-hidden="true"
            />
          </button>

        </div>
      ),
    };
  });
};
