import React, { useEffect, useState } from "react";

import { useCreateModule } from "./hooks/useCreateModule";
import { useUpdateModule } from "./hooks/useUpdateModule";
import { QalboxInputWrapper } from "../../qalbox/helperComponents/qalboxInputWrapperComponent";
import { QalboxTextInput } from "../../qalbox/helperComponents/qalboxTextInput";
import QalboxSelectDropdown from "../../qalbox/media/helperComponents/qalboxSelect";
import { Col, Row } from "react-bootstrap";
import { qalBoxSearchMediaV2Hook } from "../../../hooks";
import { convertArrayToSelectList, convertBooleanArrayToSelectList, convertToSelectObject } from "../../qalbox/helper";
import { convertMediaArrayToSelectList, convertMediaArrayToSelectObject } from "../helpers/mediaDropdownHelper";

const initialOrgObject = {
  course_id: "",
  title: "",
  description: [],
  status: "",
  completion_text: "",
  completion_criteria_percentage: "",
  level: "",
  open_for_enrollment: false,
  access_id: null,
  order: 0,
  tags: []
};

const search_config = {
  language_code: "en",
  filterMediaType: "course",
  paginate: {
    page: 1,
    size: 200,
  },
};

export const ModuleModal = ({ data, handleUpdates }) => {
  const [module, setModule] = useState(initialOrgObject);
  const [courseData, setOperationData] = qalBoxSearchMediaV2Hook();
  const [createCourseModuleHook] = useCreateModule();
  const [updateModuleHook] = useUpdateModule();

  const requestCourseData = () => {
    setOperationData({
      searchConfig: {
        ...search_config,
      },
    });
  };

  useEffect(() => {
    requestCourseData()
  }, [])

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    const formatValue = {
      tags: (val) => val.split(", "),
      description: (val) => val.split("\n"),
    };
  

    setModule((prevState) => ({
      ...prevState,
      [name]: formatValue[name] ? formatValue[name](value) : value,
    }));
  };

  const handleSelect = (name, option) => {
    setModule((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));
  };

  const handleOnSave = () => {
    createCourseModuleHook(module, handleUpdates);
  };

  const handleOnUpdate = async () => {
    updateModuleHook(module, handleUpdates);
  };

  useEffect(() => {
    if (data) {
      setModule(data);
    }
  }, []);

  return (
    <div>
      <Row>
        <Col md={6}>
          <label>Select Course</label>
          <QalboxSelectDropdown
              name={"course_id"}
              selectedOption={convertMediaArrayToSelectObject(courseData?.data ?? [], module.course_id)}
              options={convertMediaArrayToSelectList(courseData?.data)}
              onChange={handleSelect}
              disabled={courseData?.isLoading}
          />
        </Col>
        <Col md={6}>
          <QalboxTextInput
            label="Module Title"
            name="title"
            value={module.title}
            onChange={handleInputTextChange}
          />
        </Col>
      </Row>
      

      <Row>
        <Col md={3}>
          <label>Select Module Level</label>
          <QalboxSelectDropdown
              name={"level"}
              selectedOption={convertToSelectObject(module.level)}
              options={convertArrayToSelectList(["BEGINNER", "INTERMEDIATE", "ADVANCED"])}
              onChange={handleSelect}
          />
        </Col>

        <Col md={6}>
            <QalboxTextInput
              label="Tags"
              name="tags"
              value={module.tags.join(", ")}
              onChange={handleInputTextChange}
              placeholder={'Upcoming, New Intake'}
            />
        </Col>

        <Col md={3}>
          <QalboxTextInput
            label="Order"
            name="order"
            value={module.order}
            onChange={handleInputTextChange}
          />
        </Col>
      </Row>

      <Row style={{marginTop: "15px"}}>
        <Col md={6}>
          <QalboxTextInput
            label="Completion Text"
            name="completion_text"
            value={module.completion_text}
            onChange={handleInputTextChange}
          />
        </Col>
        <Col md={6}>
          <QalboxTextInput
            label="Completion Percentage"
            name="completion_criteria_percentage"
            value={module.completion_criteria_percentage}
            onChange={handleInputTextChange}
          />
        </Col>

      </Row>

      <Row>
        <Col md={6}>
            <label>Module Status</label>
            <QalboxSelectDropdown
                name={"status"}
                selectedOption={convertToSelectObject(module.status)}
                options={convertArrayToSelectList(["active", "inactive"])}
                onChange={handleSelect}
            />
        </Col>
          
        <Col md={6}>
              <label>Open for enrollment</label>
              <QalboxSelectDropdown
                  name={"open_for_enrollment"}
                  selectedOption={convertToSelectObject(JSON.stringify(module.open_for_enrollment))}
                  options={convertBooleanArrayToSelectList(["true", "false"])}
                  onChange={handleSelect}
              />
        </Col>
      </Row>

      <label style={{ marginTop: "15px" }}>Description</label>
      <textarea
        label="Description"
        name="description"
        className="form-control"
        value={module.description.join("\n")}
        onChange={handleInputTextChange}
        rows={5}
        placeholder="Enter new line each after sentence end"
      />


      
      <br></br>
      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          data ? (
            <button className="pagination-button" onClick={handleOnUpdate}>
              Update
            </button>
          ) : (
            <button className="pagination-button" onClick={handleOnSave}>
              Save
            </button>
          )
        }
      />
    </div>
  );
};