import { useState, useEffect } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import { MP_CF_QALBOX_CALENDLY_EVENTS } from '../../../../Constants';

export const useFetchCalendlyEvents = () => {
  const [calendlyEvents, setCalendlyEvents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axiosService.get(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${MP_CF_QALBOX_CALENDLY_EVENTS}`
        );
        setCalendlyEvents(response?.data?.collection);
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while fetching calendly events');
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();

  }, []);

  return [ calendlyEvents, isLoading, error ];
};