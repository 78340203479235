import React, { useEffect, useState } from "react";
import { useUpdateFaq } from "./hooks/useUpdateFaq";
import { QalboxInputWrapper } from "../../qalbox/helperComponents/qalboxInputWrapperComponent";
import { useCreateFaq } from "./hooks/useCreateFaq";
import { qalBoxSearchMediaV2Hook } from "../../../hooks";
import QalboxSelectDropdown from "../../qalbox/media/helperComponents/qalboxSelect";
import { convertMediaArrayToSelectList, convertMediaArrayToSelectObject } from "../helpers/mediaDropdownHelper";
import { QalboxTextInput } from "../../qalbox/helperComponents/qalboxTextInput";



const search_config = {
  language_code: "en",
  filterMediaType: "course",
  paginate: {
    page: 1,
    size: 200,
  },
};

export const FaqModal = ({ data, handleUpdates }) => {
  const [courseId, setCourseId] = useState(data?.course_id ?? "");
  const [faq, setFaq] = useState(data?.faq ?? [])
  const [courseData, setOperationData] = qalBoxSearchMediaV2Hook();
  const [createFaqHook] = useCreateFaq();
  const [updateFaqHook] = useUpdateFaq();

  const requestCourseData = () => {
    setOperationData({
      searchConfig: {
        ...search_config,
      },
    });
  };

  useEffect(() => {
    requestCourseData()
  }, [])

  const handleSelect = (name, option) => {
    setCourseId(option.value);
  };

  const handleOnSave = () => {
    const courseFaq = {course_id: courseId, faq: faq}
    createFaqHook(courseFaq, handleUpdates);
  };

  const handleOnUpdate = async () => {
    const courseFaq = {course_id: courseId, faq: faq}
    updateFaqHook(courseFaq, handleUpdates);
  };

  const handleAddNew = () => {
    setFaq([...faq, {question: '', answer: ''}])
  }

  const handleRemove = (idx) => {
    setFaq(faq.filter((_, index) => index !== idx));
  };

  const handleFaqChange = (e, idx) => {
    let { name, value } = e.target;
    const faqs = faq;
    faqs[idx] = {...faqs[idx], [name]: value}

    setFaq([...faqs])
  }

  useEffect(() => {
    if (data) {
      setFaq(data?.faq ?? []);
      setCourseId(data?.course_id ?? "");
    }
  }, [data]);

  return (
    <div>
      <label>Select Course</label>
      <QalboxSelectDropdown
              name={"course_id"}
              selectedOption={convertMediaArrayToSelectObject(courseData?.data ?? [], courseId)}
              options={convertMediaArrayToSelectList(courseData?.data)}
              onChange={handleSelect}
              disabled={courseData?.isLoading}
        />
      <br></br>

      <label>Faq</label>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
        {
          faq?.map((item, idx) => {
            return <div style={{border: "1px solid #ccc", padding: '10px', borderRadius: '8px', marginBottom: "10px"}}>
              <button onClick={() => handleRemove(idx)} style={{marginBottom:'5px'}} className='button'>X</button>
              <QalboxTextInput
                label={`Question ${idx+1}`}
                name="question"
                value={item.question}
                onChange={(e) => handleFaqChange(e, idx)}
              />
              <QalboxTextInput
                label="Answer"
                name="answer"
                value={item.answer}
                onChange={(e) => handleFaqChange(e, idx)}
              />
            </div>
          })
        }
      </div>

      <button className="pagination-button" onClick={handleAddNew}>
        +
      </button>

      <hr/>

      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          data ? (
            <button className="pagination-button" onClick={handleOnUpdate}>
              Update
            </button>
          ) : (
            <button className="pagination-button" onClick={handleOnSave}>
              Save
            </button>
          )
        }
      />
    </div>
  );
};