export const QalboxTextInput = ({style, label, name, value, onChange, placeholder}) => {
  return (
    <div className="form-group" style={style}>
      <label>{label}</label>
      <input
        name={name}
        value={value}
        onChange={onChange}
        type="text"
        className="form-control"
        placeholder={placeholder ?? label}
      />
    </div>
  );
};