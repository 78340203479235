import _ from "lodash";

export const convertAccessArrayToSelectList = (objectArray) => {
  return _.map(objectArray, (item) => {
    return {
      value: item.id,
      label: item.sku_name,
    };
  });
};

export const convertAccessArrayToSelectObject = (objectArray, value) => {
    const findData = objectArray?.find(item => item.id === value)

    return {
      label: findData?.sku_name,
      value: value,
    };
};