import _ from "lodash";

export const getDeletableFaq = (items) => {
    const rows = _.map(items, (item, index) => {
      const renderedCells = _.reduce(
        item.original,
        (result, value, key) => {
          if (
            _.includes(
              ["course_id", "title"],
              key
            )
          ) {
            const textValue = _.get(value, "props.text") || value;
           
              result.push(<td key={key}>{textValue}</td>);
  
          }
          return result;
        },
        []
      );
  
      return <tr key={index}>{renderedCells}</tr>;
    });
  
    const tableHeaders = (
      <thead>
        <tr>
          <th>Course Title</th>
          <th>Course uuid</th>
        </tr>
      </thead>
    );
  
    return (
      <div>
        <table className={"table mp-content-v2-preview-table small"}>
          {tableHeaders}
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  };
  