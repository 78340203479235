import _ from "lodash";
import { CopyItem } from "../../../qalbox/common";


export const getFaqDisplayData = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    const course_id = _.get(item, "course_id");

    return {
      title: _.get(item, "title"),
      course_id: <CopyItem text={course_id} />,
    };
  });
};
