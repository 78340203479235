import _ from "lodash";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import { confirmAlert } from "react-confirm-alert";
import AppModal from "../../../widgets/modal";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { FaqModal } from "./faqModal";
import { getFaqDisplayData } from "./helpers/faqDisplayData";
import { getFaqColumns } from "./helpers/faqColumns";
import { useFetchCourseFaq } from "./hooks/useFetchCourseFaq";
import { useDeleteFaq } from "./hooks/useDeleteFaq";
import { getDeletableFaq } from "./helpers/deletableFaq";
import { QalBoxDisplayUtil } from "../../qalbox/utils/qalboxModalUtil";
import FaqProdSyncModal from "./prodSyncModal";

const CourseFaq = () => {
  const [refresh, setRefresh] = useState(false);
  const [ShowMediaAccessModal, setShowMediaAccessModal] = useState(false);
  const [organizationRow, setOrganizationRow] = useState(null);
  const [faqResponse, isLoadingFaq, errorFetching] = useFetchCourseFaq(refresh);
  const [deleteFaq, isDeleting] = useDeleteFaq();

  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} faq items ?`}
            modalBody={getDeletableFaq(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const uuidListToDelete = _.map(selectedFlatRows, "values.course_id.props.text");
              uuidListToDelete.forEach(uuidListToDelete => {
                deleteFaq(uuidListToDelete, handleRefresh);
              });

              alert('Successfully Deleted !!!');
              resetSelectedRows();
            }}
          />
        );
      },
    });
  };

  const onEditClicked = (selectedFlatRows, resetSelectedRows) => {
    const id = _.get(selectedFlatRows, '[0].values.course_id.props.text', null);
    const shortMediaItem = faqResponse.find(item => item.course_id === id);
    setOrganizationRow(shortMediaItem);
    setShowMediaAccessModal(true);
    resetSelectedRows();
  };

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const onUpdate = (response) => {
    setShowMediaAccessModal(false);
    handleRefresh();
  };

  const handleCreate = () => {
    setOrganizationRow(null);
    setShowMediaAccessModal(true);
  };

    const onProdSyncCompleted = (resetSelectedRows) => {
      toast.success(
        `Production sync completed successfully.`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
  
      resetSelectedRows();
      onUpdate()
    }
  
    const handleProdSync = (selectedFlatRows, resetSelectedRows) => {
      const faqCourseIds = selectedFlatRows.map(item => item.values.course_id.props.text);
      const faqToSync = faqResponse.filter(faq => faqCourseIds.includes(faq.course_id));
  
      QalBoxDisplayUtil.triggerAppModal(
        `Prod Sync`,
        <FaqProdSyncModal
          faq={faqToSync}
          onProdSyncCompleted={() => {
            onProdSyncCompleted(resetSelectedRows);
          }}
        />
      );
    }

  return (
    <LoadingOverlay
      active={isLoadingFaq || isDeleting}
      spinner={<ScaleLoader />}
      text={isDeleting ? "Deleting the selected faq items." : "Loading faq..."}
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Course Faq"}</h3>
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <button
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
              onClick={handleCreate}
            >
              Create
            </button>
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: getFaqDisplayData(faqResponse),
            columns: getFaqColumns(),
            handleDelete: onDeleteSelected,
            handleEdit: onEditClicked,
            handleProdSync: process.env.REACT_APP_IS_PROD_SYNC_AVAILABLE
              ? handleProdSync
              : null,
            isLoading: isLoadingFaq,
            tableError: errorFetching,
          }}
        />
      </BasicLayout>
      <AppModal
        show={ShowMediaAccessModal}
        showBody={true}
        headingText={`Create Faq`}
        modalBody={
          <FaqModal
            data={organizationRow}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowMediaAccessModal(false); setOrganizationRow(null)}}
      />
    </LoadingOverlay>
  );
};

export default CourseFaq;
