import _ from "lodash";
import React from "react";
import { CopyItem } from "../../common";

export const getMediaItemsColumnsForProdSync = () => {
  return React.useMemo(() => [
    {
      Header: "UUID",
      accessor: "uuid",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
        Header: "Prod UUID",
        accessor: "prod_uuid",
        disableSortBy: true,
        disableFilters: true,
        width: 200,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
    {
      Header: "Title",
      accessor: "title",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Type",
      accessor: "type",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Language",
      accessor: "language",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Series ID",
      accessor: "series_meta_series_id",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
  ]);
};

export const getSearchMediaDisplayDataProdSync = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    const id = _.get(item, "id");
    const title = _.get(item, "title");

    return {
      uuid: <CopyItem text={_.get(item, "uuid")} />,
      prod_uuid: <CopyItem text={_.get(item, "prod_uuid")} />,
      type: _.get(item, "type"),
      language: _.get(item, "language"),
      title: <CopyItem text={title} />,
      series_meta_series_id: _.get(item, "series_meta_series_id"),
    };
  });
};

export const getMediaIds = (mediaItems) => {
  return _.map(mediaItems, "original.uuid.props.text");
};
