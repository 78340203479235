import { MPSelect } from "../../../../widgets/common";
import { convertArrayToObjMap } from "../../helper";
import { QalboxSimpleBrandsDropdown } from "./QalboxSimpleBrandsDropdown";

export const QalboxDisplayCategoryBrandDetails = ({
  is_branded_partner_rail = false,
  branded_partner_uuid,
  rail_content_type,
  onChangeSelect,
  handleInputCheckBoxChange,
  handleSelect,
}) => {
  return (
    <>
      <div className="form-group">
        <label>Is Branded Partner Rail</label>
        <input
          type="checkbox"
          name="is_branded_partner_rail"
          checked={is_branded_partner_rail}
          onChange={handleInputCheckBoxChange}
          style={{ transform: "scale(1.5)", marginLeft: "10px" }}
        />
      </div>
      <div className="qalbox-form-row">
        <QalboxSimpleBrandsDropdown
          brandUuid={branded_partner_uuid}
          handleSelect={handleSelect}
        />
        <div className="form-group" style={{ marginLeft: "5px" }}>
          <label>Rail Content Type</label>
          <MPSelect
            items={convertArrayToObjMap(["branded_content", "brand_page", "qalbox_courses"])}
            selectedItem={rail_content_type}
            onChangeSelect={onChangeSelect}
          />
        </div>
      </div>
    </>
  );
};
