import React from "react";

export const getProdFaqColumns = () => {
  return React.useMemo(() => [
    {
      Header: "Course Id",
      accessor: "course_id",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Prod Course Id",
      accessor: "prod_course_id",
      disableSortBy: true,
      disableFilters: true,
      width: 300,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    }
  ]);
};
