import _ from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import { confirmAlert } from "react-confirm-alert";
import AppModal from "../../../widgets/modal";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { ModuleModal } from "./moduleModal";
import { LessonModal } from "../lessons/lessonModal";
import { getModulesDisplayData } from "./helpers/modulesDataTransform";
import { getModulesColumns } from "./helpers/modulesColumns";
import { useFetchModules } from "./hooks/useFetchModules";
import { useDeleteModule } from "./hooks/useDeleteModule";
import { getDeletableModule } from "./helpers/deletableModule";
import { ResourcesModal } from "../resources/resourcesModal";
import { RevisionModal } from "../module-revision/revisionModal";
import { QuizModal } from "../quiz/quizModal";
import { QalBoxDisplayUtil } from "../../qalbox/utils/qalboxModalUtil";
import ModuleProdSyncModal from "./prodSyncModal";

const IqraModules = () => {
  const history = useHistory();
  const [showModuleDetailsModal, setShowModuleDetailsModal] = useState(false)
  const [showLessonsModal, setShowLessonsModal] = useState(false)
  const [showResourceModal, setShowResourceModal] = useState(false)
  const [showRevisionModal, setShowRevisionModal] = useState(false)
  const [showQuizModal, setShowQuizModal] = useState(false)

  const [refresh, setRefresh] = useState(false);
  const [moduleRow, setModuleRow] = useState(null);
  const [modulesResponse, isLoadingModules, errorFetching] = useFetchModules(refresh);
  const [deleteModule, isDeleting] = useDeleteModule();

  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    console.log("slected rows: ", selectedFlatRows);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} module items ?`}
            modalBody={getDeletableModule(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const uuidListToDelete = _.map(selectedFlatRows, "values.id");
              _.forEach(uuidListToDelete, id => {
                deleteModule(id, handleRefresh);
              });

              alert('Successfully Deleted !!!');
              resetSelectedRows();
            }}
          />
        );
      },
    });
  };


  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const onUpdate = (response) => {
    setShowModuleDetailsModal(false);
    setShowLessonsModal(false);
    setShowResourceModal(false);
    setShowRevisionModal(false);
    setShowQuizModal(false);
    handleRefresh();
    console.log("on update hitting...")
  };

  const handleCreate = () => {
    setModuleRow(null);
    setShowModuleDetailsModal(true);
  };

  const handleRowClickView = (row, columnID) => {
    setModuleRow(row);

    switch (columnID) {
      case 1:
        setShowModuleDetailsModal(true);
        break;
      case 2:
        setShowLessonsModal(true);
        break;
      case 3:
        setShowResourceModal(true);
        break;
      case 4:
        setShowRevisionModal(true);
        break;
      case 5:
        setShowQuizModal(true);
        break;
      default:
        break;
    }
  };

  const handleCertificationModal = (row) => {
    setModuleRow(row);
    console.log(row.uuid)
    history.push(`/iqra/certification-editor?module_uuid=${row.uuid}&module_title=${encodeURIComponent(row.title)}`);
  };

  const onProdSyncCompleted = (resetSelectedRows) => {
    toast.success(
      `Production sync completed successfully.`,
      {
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    resetSelectedRows();
    onUpdate()
  }

  const handleProdSync = (selectedFlatRows, resetSelectedRows) => {
    const moduleId = selectedFlatRows[0].values.id;
    const moduleToSync = modulesResponse.find(module => module.id === moduleId);

    QalBoxDisplayUtil.triggerAppModal(
      `Prod Sync`,
      <ModuleProdSyncModal
        module={moduleToSync}
        onProdSyncCompleted={() => {
          onProdSyncCompleted(resetSelectedRows);
        }}
      />
    );
  }

  return (
    <LoadingOverlay
      active={isLoadingModules || isDeleting}
      spinner={<ScaleLoader />}
      text={isDeleting ? "Deleting the selected Modules items." : "Loading Modules..."}
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Modules"}</h3>
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <button
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
              onClick={handleCreate}
            >
              Create
            </button>
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: getModulesDisplayData(modulesResponse, handleRowClickView, handleCertificationModal),
            columns: getModulesColumns(),
            handleDelete: onDeleteSelected,
            handleProdSync: process.env.REACT_APP_IS_PROD_SYNC_AVAILABLE
              ? handleProdSync
              : null,
            isLoading: isLoadingModules,
            tableError: errorFetching,
          }}
        />
      </BasicLayout>

      {/* Module Details Modal */}
      <AppModal
        show={showModuleDetailsModal}
        showBody={true}
        headingText={`Course Module`}
        modalBody={
          <ModuleModal
            data={moduleRow}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowModuleDetailsModal(false); setModuleRow(null)}}
      />

      {/* Lessons Modal  */}
      <AppModal
        show={showLessonsModal}
        showBody={true}
        headingText={`Lessons`}
        modalBody={
          <LessonModal
            data={moduleRow}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowLessonsModal(false); setModuleRow(null)}}
      />

      {/* Resource Modal  */}
      <AppModal
        show={showResourceModal}
        showBody={true}
        headingText={`Resources`}
        modalBody={
          <ResourcesModal
            data={moduleRow}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowResourceModal(false); setModuleRow(null)}}
      />

      {/* Revision Modal  */}
      <AppModal
        show={showRevisionModal}
        showBody={true}
        headingText={`Revision`}
        modalBody={
          <RevisionModal
            data={moduleRow}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowRevisionModal(false); setModuleRow(null)}}
      />

      {/* Quiz Modal  */}
      <AppModal
        show={showQuizModal}
        showBody={true}
        headingText={`Quiz`}
        modalBody={
          <QuizModal
            data={moduleRow}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowQuizModal(false); setModuleRow(null)}}
      />
    </LoadingOverlay>
  );
};

export default IqraModules;
