import _ from "lodash";
import { QalboxTextInput } from "../../qalbox/helperComponents/qalboxTextInput";
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row, Alert, Collapse, Panel, Text } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import { ScaleLoader } from "react-spinners";
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
import * as fabric from 'fabric';
import { useLocation } from "react-router-dom";
import { useUpdateModuleCertification } from "./hooks/useUpdateModuleCertification";
import { MPExpandIcon } from "../../../widgets/common";

export default function CertificationEditor() {  
  const { editor, onReady } = useFabricJSEditor();
  const history = [];
  const [color, setColor] = useState("#000000");
  const [cropImage, setCropImage] = useState(true);
  const [imageURL, setImageURL] = useState("");
  const [config, setConfig] = useState({
    studentNamePlacerHolderConfigfontSize: 25,
    studentNamePlacerHolderConfigfill: color,
    certNamePlacerHolderConfigfontSize: 30,
    certNamePlacerHolderConfigfill: color,
    datePlacerHolderConfigfontSize: 20,
    datePlacerHolderConfigfill: color,
  });
  const [updateModuleCertification] = useUpdateModuleCertification()
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const handlePlaceHolderValuesChange = (e) => {
    const { name, value } = e.target;
    setConfig((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setImageURL(value);
  };

  const studentNameholderText = new fabric.Text('Student Name', {
    left: 0,
    top: 0,
    fontSize: config.studentNamePlacerHolderConfigfontSize,
    fill: config.studentNamePlacerHolderConfigfill,
  });

  const certificationNameholderText = new fabric.Text('Certification Name', {
    left: 0,
    top: 0,
    fontSize: config.certNamePlacerHolderConfigfontSize,
    fill: config.certNamePlacerHolderConfigfill,
  });

  const certificationDateholderText = new fabric.Text('Certification Date', {
    left: 0,
    top: 0,
    fontSize: config.datePlacerHolderConfigfontSize,
    fill: config.datePlacerHolderConfigfill,
  });

  useEffect(async () => {
    if (!editor || !fabric) {
      return;
    }

    if (cropImage) {
      editor.canvas.__eventListeners = {};
      return;
    }

    if (!editor.canvas.__eventListeners["mouse:wheel"]) {
      editor.canvas.on("mouse:wheel", function (opt) {
        var delta = opt.e.deltaY;
        var zoom = editor.canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        editor.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });
    }

    if (!editor.canvas.__eventListeners["mouse:down"]) {
      editor.canvas.on("mouse:down", function (opt) {
        var evt = opt.e;
        if (evt.ctrlKey === true) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
        }
      });
    }

    if (!editor.canvas.__eventListeners["mouse:move"]) {
      editor.canvas.on("mouse:move", function (opt) {
        if (this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });
    }

    if (!editor.canvas.__eventListeners["mouse:up"]) {
      editor.canvas.on("mouse:up", function (opt) {
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
      });
    }

    editor.canvas.renderAll();
    
  }, [editor]);

  const getCanvasAndBackgroundSize = (canvas) => {
    if (!canvas) return null;
  
    // Get the canvas size
    const canvasSize = {
      width: canvas.width,
      height: canvas.height,
    };
  
    // Get the background image size
    const backgroundSize = canvas.backgroundImage ? {
      width: canvas.backgroundImage.width,
      height: canvas.backgroundImage.height,
    } : null;
  
    console.log("Canvas Size:", canvasSize);
    console.log("Background Image Size:", backgroundSize);
  
    return { canvasSize, backgroundSize };
  };

  const getTextPosition = (textObject) => {
    if (textObject) {
      const position = {
        left: textObject.left,
        top: textObject.top,
      };
      console.log("Text Position:", position);
      return position;
    }
    return null;
  };

  const storeTemplate = async () => {
    console.log(studentNameholderText)
    console.log(getTextPosition(studentNameholderText))
    console.log(getTextPosition(certificationDateholderText))
    console.log(getTextPosition(certificationNameholderText))
    const studentNameholderPosition = getTextPosition(studentNameholderText)
    const certificationDateholderPosition = getTextPosition(certificationDateholderText)
    const certificationNameholderPosition = getTextPosition(certificationNameholderText)
    const sizes = getCanvasAndBackgroundSize(editor.canvas);
    
    const backgroundImage = editor.canvas.backgroundImage;
  
    // Get background image size (scaled size)
    const backgroundWidth = backgroundImage ? backgroundImage.width * backgroundImage.scaleX : 0;
    const backgroundHeight = backgroundImage ? backgroundImage.height * backgroundImage.scaleY : 0;
  
    const { canvasSize, backgroundSize } = sizes;
    let payload = {
      certTemplateUrl:imageURL,
      canvasSize,
      backgroundSize,
      backgroundPosition: {
        left: backgroundImage ? backgroundImage.left : 0,
        top: backgroundImage ? backgroundImage.top : 0
      },
      scaled: {
        backgroundWidth,
        backgroundHeight
      },
      canvas: {
        height: 707,
        width: 1000
      },
      placeholders: {
        studentName: {
          text: "Student Name Placeholder",
          left: studentNameholderPosition.left,
          top: studentNameholderPosition.top,
          fontSize: studentNameholderText.fontSize,
          color: studentNameholderText.fill,
        },
        certificateName: {
          text: "Certification Name Placeholder",
          left: certificationNameholderPosition.left,
          top: certificationNameholderPosition.top,
          fontSize: certificationDateholderText.fontSize,
          color: certificationDateholderText.fill,
        },
        certificateDate: {
          text: "Date Placeholder",
          left: certificationDateholderPosition.left,
          top: certificationDateholderPosition.top,
          fontSize: certificationDateholderText.fontSize,
          color: certificationDateholderText.fill,
        }
      }
    }

    console.log({
      payload
    })

    updateModuleCertification(queryParams.get("module_uuid"), payload, {});
    alert('The Certification has been stored for Issuing.')
  }

  const downloadCanvasImage = () => {
    if (!editor || !fabric) {
      return;
    }

    //Dynamically Setting actual text before downloading
    studentNameholderText.set({ text: "Taylor Thomas" });
    certificationNameholderText.set({ text: "Proficient is Arabic" });
    certificationDateholderText.set({ text: "02/12/2024" });

    const dataURL = editor.canvas.toDataURL({
      format: "png",
      quality: 1.0,
    });

    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "sample-certification.png";
    link.click();
  };

  const addBackground = async () => {
    if (!editor || !fabric) {
      return;
    }
    const myFabricImageWithLogoPng = await fabric.FabricImage.fromURL(
      imageURL,
      {
        'crossOrigin': 'anonymous'
      },
      { 
          height: 707,
          width: 1000
      }
    );
    editor.canvas.height = 707;
    editor.canvas.width = 1000;
    editor.canvas.backgroundImage = myFabricImageWithLogoPng;
    editor.canvas.originX = "left";
    editor.canvas.originY = "top";
    editor.canvas.scaleX =  0.5;
    editor.canvas.scaleY = 0.5;
    editor.canvas.setHeight(707);
    editor.canvas.setWidth(1000);
    editor.canvas.renderAll.bind(editor.canvas);
  };

  useEffect(async () => {
    if (!editor || !fabric) {
      return;
    }
    await addBackground();
    editor.canvas.renderAll();
  }, [editor?.canvas.backgroundImage]);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.freeDrawingBrush.color = color;
    editor.setStrokeColor(color);
  }, [color]);

  const undo = () => {
    if (editor.canvas._objects.length > 0) {
      const lastObject = editor.canvas._objects.pop();
      history.push(lastObject);
      editor.canvas.remove(lastObject);
      editor.canvas.renderAll();
    }
  };
  
  const clear = () => {
    editor.canvas.clear();
    history.length = 0;
  };

  const removeSelectedObject = () => {
    editor.canvas.remove(editor.canvas.getActiveObject());
  };

  const addStudentPlaceholderText = () => {
    editor.canvas.add(studentNameholderText);
  };

  const addCertificationNamePlaceholderText = () => {
    editor.canvas.add(certificationNameholderText);
  };

  const addCertificationDatePlaceholderText = () => {
    editor.canvas.add(certificationDateholderText);
  };

  const exportSVG = () => {
    const svg = editor.canvas.toSVG();
    console.info(svg);
  };

  const sampleExcelFileUrl = 'https://storage.googleapis.com/mp_cdn_origin/images/qalbox/en/featured/large/cert.jpg';

  return (
    <LoadingOverlay
      active={false}
      spinner={<ScaleLoader />}
      text={""}
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={1000} />
        <Row>
        <div>
            <h2>Iqra Certification Editor</h2>
            <br/>

            <Row>
              <Col>
                <Alert>
                  <strong style={{'fontSize': '20px'}}>
                    Important Note (Please check image format and size before you save for Issuing):
                  </strong>
                  <br/>
                  <Collapse
                    accordion={true}
                    className={'upload-note-collapse'}
                    expandIcon={MPExpandIcon}
                  >
                    <Panel
                      header="Excel Upload Guide"
                      headerClass="excel-upload-note-header"
                      extra={<span>Click to see/hide Details</span>}
                    >
                      <ol>
                        <li>
                          Please see the sample file for format.
                        </li>
                        <li>
                          All fields that are marked with&nbsp;
                          <strong><u>required</u></strong>&nbsp;
                          should be available when uploading the file.
                        </li>
                        <li>
                          <a href="https://storage.googleapis.com/mp_cdn_origin/images/qalbox/en/featured/large/cert.jpg" >SAMPLE Document</a>
                        </li>
                      </ol>
                    </Panel>
                  </Collapse>
                  <label>Certificate image [png|jpg] - (height: 707 px width: 1000 px)</label> &nbsp;
                  <a href={sampleExcelFileUrl} target="_blank">
                    Click to see the sample certificate.
                  </a>
                </Alert>
              </Col>
            </Row>

            <input
                value={imageURL}
                name="imageURL"
                type="text"
                onChange={handleInputTextChange}
                className="form-control"
                placeholder="Image Url"
                style={{ width: "1000px" }}
            />
            <br/>
            <button 
              onClick={addBackground} 
              disabled={!cropImage}
              style={{
                display: "inline-block",
                padding: "5px 10px",
                backgroundColor: "white",
                color: "green",
                textDecoration: "none",
                borderRadius: "4px",
              }}
            >
              Load Image
            </button>
            <button 
              onClick={addStudentPlaceholderText} 
              disabled={!cropImage}
              style={{
                display: "inline-block",
                padding: "5px 10px",
                backgroundColor: "white",
                color: "green",
                textDecoration: "none",
                borderRadius: "4px",
              }}
            >
              Add Student Name Placeholder
            </button>
            <button 
              onClick={addCertificationNamePlaceholderText} 
              disabled={!cropImage}
              style={{
                display: "inline-block",
                padding: "5px 10px",
                backgroundColor: "white",
                color: "green",
                textDecoration: "none",
                borderRadius: "4px",
              }}
            >
              Add Certification Name Placeholder
            </button>
            <button 
              onClick={addCertificationDatePlaceholderText} 
              disabled={!cropImage}
              style={{
                display: "inline-block",
                padding: "5px 10px",
                backgroundColor: "white",
                color: "green",
                textDecoration: "none",
                borderRadius: "4px",
              }}
            >
              Add Certification Date Placeholder
            </button>
            <br></br>
        </div>
        </Row>
          <Row>
            <Col md={4}>
                
            </Col>
            <Col md={4}>
              
            </Col>
            <Col md={4}>
              
            </Col>
          </Row>

          <br></br>    
            
          <Row>
             <Col md={2}>
                <QalboxTextInput
                  name="studentNamePlacerHolderConfigfill"
                  label="Student Name Colour"
                  value={config.studentNamePlacerHolderConfigfill}
                  onChange={handlePlaceHolderValuesChange}
                />
              </Col>
              <Col md={2}>
                <QalboxTextInput
                  name="datePlacerHolderConfigfill"
                  label="Module Name Colour"
                  value={config.datePlacerHolderConfigfill}
                  onChange={handlePlaceHolderValuesChange}
                />
              </Col>  
              <Col md={2}>
                <QalboxTextInput
                  name="certNamePlacerHolderConfigfill"
                  label="Date Colour"
                  value={config.certNamePlacerHolderConfigfill}
                  onChange={handlePlaceHolderValuesChange}
                />
              </Col>         
            </Row>

            <Row>
             <Col md={2}>
                <QalboxTextInput
                  name="studentNamePlacerHolderConfigfontSize"
                  label="Student Name Size"
                  value={config.studentNamePlacerHolderConfigfontSize}
                  onChange={handlePlaceHolderValuesChange}
                />
              </Col>
              <Col md={2}>
                <QalboxTextInput
                  name="certNamePlacerHolderConfigfontSize"
                  label="Module Name Size"
                  value={config.certNamePlacerHolderConfigfontSize}
                  onChange={handlePlaceHolderValuesChange}
                />
              </Col>  
              <Col md={2}>
                <QalboxTextInput
                  name="datePlacerHolderConfigfontSize"
                  label="Date Size"
                  value={config.datePlacerHolderConfigfontSize}
                  onChange={handlePlaceHolderValuesChange}
                />
              </Col>         
            </Row>

        <Row>
          
        <div>
      <hr></hr>
      <button 
        onClick={clear} 
        disabled={!cropImage}
        style={{
          display: "inline-block",
          padding: "5px 10px",
          backgroundColor: "white",
          color: "green",
          textDecoration: "none",
          borderRadius: "4px",
        }}
      >
        Clear Canvas
      </button>
      <button 
        onClick={undo} 
        disabled={!cropImage}
        style={{
          display: "inline-block",
          padding: "5px 10px",
          backgroundColor: "white",
          color: "green",
          textDecoration: "none",
          borderRadius: "4px",
        }}
      >
        Undo Step
      </button>
      <button 
        onClick={removeSelectedObject} 
        disabled={!cropImage}
        style={{
          display: "inline-block",
          padding: "5px 10px",
          backgroundColor: "white",
          color: "green",
          textDecoration: "none",
          borderRadius: "4px",
        }}
      >
        Delete
      </button>
      
      {/* <label disabled={!cropImage}>
        <input
          disabled={!cropImage}
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
      </label> */}
      <br></br>
      <hr></hr>
      <button 
        onClick={downloadCanvasImage}
        style={{
          display: "inline-block",
          padding: "5px 10px",
          backgroundColor: "green",
          color: "white",
          textDecoration: "none",
          borderRadius: "4px",
        }}
      >
        Download Sample Cert</button>
      <button 
        onClick={storeTemplate}
        style={{
          display: "inline-block",
          padding: "5px 10px",
          backgroundColor: "green",
          color: "white",
          textDecoration: "none",
          borderRadius: "4px",
        }}
      >
        Store Template in Backend</button>
      <br></br>
      <hr></hr>
      <div
        style={{
          border: `1px ${!cropImage ? "dotted" : "solid"} Black`,
          width: "1000px",
          height: "707px",
        }}
      >
        <FabricJSCanvas className="sample-canvas" onReady={onReady} />
      </div>
        </div>
        </Row>
      </BasicLayout>
    </LoadingOverlay>
  );
};