import React from "react";

export const getModulesColumns = () => {
  return React.useMemo(() => [
    {
      Header: "id",
      accessor: "id",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Module UUID",
      accessor: "uuid",
      disableSortBy: true,
      disableFilters: true,
      width: 300,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Course",
      accessor: "course",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Module",
      accessor: "module",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Module Details",
      accessor: "moduleDetailsModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Lessons",
      accessor: "moduleLessonsModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Resource",
      accessor: "moduleResourceModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Revision",
      accessor: "moduleRevisionModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Quiz",
      accessor: "moduleQuizModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Certification",
      accessor: "moduleCertificationModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
  ]);
};
