import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import { MP_CF_QALBOX_COURSE_MODULE } from '../../../../Constants';
import { toast } from 'react-toastify';

export const useDeleteResources = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteResource = async (moduleId, resourceId, handleRefresh) => {
    setIsLoading(true);
    setError(null);

    const confirm = window.confirm(
      `Are you sure about deleting this item ?`
    );

    if(confirm){
      try {
        const response = await axiosService.delete(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${MP_CF_QALBOX_COURSE_MODULE}/${moduleId}/resources/${resourceId}`
        );
        const json = response?.data;
        handleRefresh()
        toast.success(`Resources deleted successfully.`, {
                position: toast.POSITION.BOTTOM_LEFT
        });
        return json.success; // Return success status from the response
      } catch (err) {
        console.error(err);
        setError(err.message || 'An error occurred while deleting the media access');
        return false; // Return false if an error occurs
      } finally {
        setIsLoading(false);
      }
    }

    
  };

  return [ deleteResource, isLoading, error ];
};