import _ from "lodash";

export const convertCalendlyArrayToSelectList = (objectArray) => {
  return _.map(objectArray, (item) => {
    return {
      value: item.uri,
      label: item.name,
    };
  });
};

export const convertCalendlyArrayToSelectObject = (objectArray, value) => {
    const filtered = objectArray?.filter(item => value.includes(item.uri))

    return _.map(filtered, (item) => {
      return {
        label: item.name,
        value: item.uri,
      };
    });
};