import _ from "lodash";
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import { MPSelect } from "../../../widgets/common";
import { confirmAlert } from "react-confirm-alert";
import AppModal from "../../../widgets/modal";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { MediaAccessModal } from "./accessModal";
import { getMediaAccessDisplayData } from "./helpers/mediaAccessDataTransform";
import { getQalboxMediaAccessColumns } from "./helpers/mediaAccessColumns";
import { useFetchMediaAccess } from "./hooks/useFetchMediaAccess";
import { useDeleteMediaAccess } from "./hooks/useDeleteMediaAccess";
import { convertArrayToObjMap, getDeletableMediaAccess } from "../../qalbox/helper";

const CourseRevisionAccess = () => {
  const [refresh, setRefresh] = useState(false);
  const [ShowMediaAccessModal, setShowMediaAccessModal] = useState(false);
  const [mediaAccessRow, setMediaAccessRow] = useState(null);
  const [active, setActive] = useState(true);
  const [mediaAccesssResponse, isLoadingMediaAccess, errorFetching] = useFetchMediaAccess(active, refresh);
  const [deleteMediaAccess, isDeleting, isDeletingError] = useDeleteMediaAccess();

  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} revision access items ?`}
            modalBody={getDeletableMediaAccess(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const uuidListToDelete = _.map(selectedFlatRows, "values.id.props.text");
              _.forEach(uuidListToDelete, id => {
                deleteMediaAccess(id, handleRefresh);
              });

              alert('Successfully Deleted !!!');
              resetSelectedRows();
            }}
          />
        );
      },
    });
  };

  const onEditClicked = (selectedFlatRows, resetSelectedRows) => {
    const id = _.get(selectedFlatRows, '[0].values.id.props.text', null);
    const shortMediaItem = mediaAccesssResponse.find(item => item.id === id);
    setMediaAccessRow(shortMediaItem);
    setShowMediaAccessModal(true);
    resetSelectedRows();
  };

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const onUpdate = (response) => {
    setShowMediaAccessModal(false);
    handleRefresh();
  };

  const handleCreate = () => {
    setMediaAccessRow(null);
    setShowMediaAccessModal(true);
  };

  return (
    <LoadingOverlay
      active={isLoadingMediaAccess || isDeleting}
      spinner={<ScaleLoader />}
      text={isDeleting ? "Deleting the selected revision access items." : "Loading Revision Access..."}
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Revision Access"}</h3>
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <button
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
              onClick={handleCreate}
            >
              Create
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="form-group">
              <label
                style={{
                  marginRight: "5px",
                }}
              >
                {"Active : "}
              </label>
              <MPSelect
                items={convertArrayToObjMap(["true", "false"])}
                selectedItem={active}
                onChangeSelect={(e) => setActive(e)}
              />
            </div>
          </Col>
        </Row>
        <QalboxTable
          table={{
            data: getMediaAccessDisplayData(mediaAccesssResponse),
            columns: getQalboxMediaAccessColumns(),
            handleDelete: onDeleteSelected,
            handleEdit: onEditClicked,
            isLoading: isLoadingMediaAccess,
            tableError: errorFetching,
          }}
        />
      </BasicLayout>
      <AppModal
        show={ShowMediaAccessModal}
        showBody={true}
        headingText={`Create Revision Access`}
        modalBody={
          <MediaAccessModal
            data={mediaAccessRow}
            handleUpdates={onUpdate}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {setShowMediaAccessModal(false); setMediaAccessRow(null)}}
      />
    </LoadingOverlay>
  );
};

export default CourseRevisionAccess;
