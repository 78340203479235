import _ from 'lodash';
import {
  useState,
  useEffect,
} from 'react';
import {
  qalBoxAddDisplayCategoriesAdPool,
  qalBoxUpdateDisplayCategoriesAdPool
} from '../../services/mp-cf-api-service';

const displayCategoriesAddOrUpdate = () => {
  const [ responseData, setResponseData ] = useState({ });
  const [ updateData, setUpdateData ] = useState(null);
  const {
    items,
    onOperationComplete,
  } = updateData || {};

  const addUpdateDisplayCategoriesAdPool = async () => {
    try {
      console.log(`QalBox add or update display categories...`);
      setResponseData({
        isUpdating: true,
        isError: false,
      });
      const displayCategoriesBulkAddUpdateApiResp = null;

      console.log({items})


      if(items.id){
        displayCategoriesBulkAddUpdateApiResp = await qalBoxUpdateDisplayCategoriesAdPool({
          displayCategoryAdPool: items,
        });
      }else{
        displayCategoriesBulkAddUpdateApiResp = await qalBoxAddDisplayCategoriesAdPool({
          displayCategoryAdPool: items,
        });
      }

      const responseData = displayCategoriesBulkAddUpdateApiResp.data;
      let errorMsg = null;
      let isError = false;

      if(responseData.success) {
        console.log('QalBox display categories add/update Success!!');
      } else {
        console.log('QalBox display categories add/update Failed!!');
        errorMsg = `Failed to add/update QalBox display categories.`;
        isError = true;
      }

      setResponseData({
        isUpdating: false,
        isError,
        errorMsg,
        data: responseData.payload,
      });

      if(onOperationComplete) {
        onOperationComplete({
          isError,
          errorMsg,
          data: responseData.payload,
        });
      }
    } catch (err) {
      console.log('Something went wrong while add/update QalBox display categories. ', err);
      const errorMsg = `Error occurred while add/update QalBox display categories.`;
      setResponseData({
        isUpdating: false,
        isError: true,
        errorMsg,
        data: null,
      });
      if(onOperationComplete) {
        onOperationComplete({
          isError: true,
          errorMsg,
          data: null,
        });
      }
    }
  };

  useEffect( () => {
    if(!_.isEmpty(updateData)) {
      addUpdateDisplayCategoriesAdPool();
    }
  }, [ updateData ]);

  return [
    responseData,
    setUpdateData,
  ];
};

export default displayCategoriesAddOrUpdate;
