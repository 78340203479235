import React from "react";
import CustomFilter from "../../../../widgets/qalbox-table/custom-filter";

export const getFaqColumns = () => {
  return React.useMemo(() => [
    {
      Header: "Course Id",
      accessor: "course_id",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Course title",
      accessor: "title",
      defaultCanSort: true,
      defaultCanFilter: true,
      Filter: CustomFilter,
      width: 300,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    }
  ]);
};
